import React from "react";
import ProductRow from "./ProductRow";

const ProductsTable = ({ products, handleCallback }) => {
  const renderRows = () => {
    if (Array.isArray(products)) {
      return products.map((product) => (
        <ProductRow key={product.product_id} product={product} handleCallback={handleCallback} />
      ));
    }
  };
  return (
    <div className="table-responsive">
      <table className="table">
        <thead className="bg-light border bold">
          <tr>
            <td className="wide">Nombre</td>
            <td>Disponible</td>
            <td>Modo</td>
            <td className="wide">Ubicación</td>
            <td>Precio</td>
            <td>Acciones</td>
          </tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </table>
    </div>
  );
};

export default ProductsTable;
