import React, { useContext, useReducer } from "react";
import { createContext } from "react";
import {
  CREATE_LESSON,
  SET_PROPERTY_LESSON,
  SET_LESSON,
  LESSONS_RECIBIDAS,
  AGREGAR_MULTIMEDIA,
  AGREGAR_DESCARGABLE,
  ELIMINAR_DESCARGABLE,
  ELIMINAR_MULTMEDIA,
  SET_PROPERTY_MULTIMEDIA,
  SET_PROPERTY_DESCARGABLE,
  APPEND_LESSONS,
  CREATE_MEDIA,
  SET_LESSON_MEDIA,
  SET_LESSON_RESOURCE,
} from "../types/lessons";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import { ModalContext } from "./ModalContext";
import { navigate } from "@reach/router";
import { hideModal } from "../utils";
import LessonsReducer from "../reducers/LessonsReducer";
import LessonsService from "../services/LessonsService";
import LessonMediaService from "../services/LessonMediaService";
import AdjuntosService from "../services/AdjuntosService";

const initialState = {
  spinner: false,
  lessons: null,
  lesson: null,
  media: null,
};

export const LessonsContext = createContext(initialState);

export const LessonsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(LessonsReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getSingleLesson = (lesson_id) => {
    LessonsService.getSingleLesson(lesson_id).then((res) => {
      const { lesson } = res.data;
      dispatch({ type: SET_LESSON, payload: lesson });
    });
  };

  const getSectionLessons = (section_id) => {
    LessonsService.getSectionLessons(section_id).then((res) => {
      const { lessons } = res.data;
      dispatch({ type: LESSONS_RECIBIDAS, payload: lessons });
    });
  };

  const getSiguienteLesson = (lesson_id, course_id) => {
    LessonsService.getSiguienteLesson(lesson_id, course_id).then((res) => {
      const { lesson_id, course_id } = res.data;
      if (lesson_id) {
        navigate("../");
        navigate(`/courses/${course_id}/lesson/${lesson_id}`);
      } else {
        navigate(`/courses/${course_id}`);
      }
    });
  };

  const clearLessons = () => {
    dispatch({ type: LESSONS_RECIBIDAS, payload: null });
  };

  const clearLesson = () => {
    dispatch({ type: SET_LESSON, payload: null });
  };

  const getLessons = ({ course_id, section_id }) => {
    LessonsService.getLessons({
      course_id,
      section_id,
    }).then((res) => {
      const { lessons } = res.data;
      dispatch({ type: APPEND_LESSONS, payload: lessons });
    });
  };

  const selectLesson = (lesson) => {
    dispatch({ type: SET_LESSON, payload: lesson });
  };

  const sortLessons = (lessons) => {
    lessons = lessons.map((lesson, index) => ({
      ...lesson,
      orden: index + 1,
    }));
    dispatch({ type: LESSONS_RECIBIDAS, payload: lessons });
  };

  const setPropertyLesson = (key, value) => {
    dispatch({ type: SET_PROPERTY_LESSON, payload: { key, value } });
  };

  const createLesson = () => {
    dispatch({ type: CREATE_LESSON });
  };

  const postLesson = (lesson) => {
    dispatch({ type: SHOW_SPINNER });
    let service = LessonsService.putLesson;
    if (isNaN(lesson.lesson_id)) {
      service = LessonsService.postLesson;
    }
    const promises = [];
    if (lesson.picture && lesson.picture !== null) {
      let formData = AdjuntosService.getFormData(lesson.picture);
      promises.push(
        new Promise((resolve, reject) => {
          AdjuntosService.postAdjunto(formData)
            .then((res) => {
              const { file_id } = res.data;
              lesson.file_id = file_id;
              resolve();
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        })
      );
    }
    Promise.all(promises).then(() => {
      service(lesson)
        .then(() => {
          hideModal();
          success("Lección guardada.");
          dispatch({ type: HIDE_SPINNER });
          getSectionLessons(lesson.section_id);
        })
        .catch((error) => {
          alert(error);
          dispatch({ type: HIDE_SPINNER });
        });
    });
  };

  const agregarMultimediaLesson = () => {
    dispatch({ type: AGREGAR_MULTIMEDIA });
  };

  const eliminarMultimedia = (multimedia) => {
    dispatch({ type: ELIMINAR_MULTMEDIA, payload: multimedia });
  };

  const eliminarDescargable = (descargable) => {
    dispatch({ type: ELIMINAR_DESCARGABLE, payload: descargable });
  };

  const agregarDescargableLesson = () => {
    dispatch({ type: AGREGAR_DESCARGABLE });
  };

  const setPropertyMedia = (key, value) => {
    dispatch({
      type: SET_PROPERTY_MULTIMEDIA,
      payload: { key, value },
    });
  };

  const setPropertyResource = (key, value) => {
    dispatch({
      type: SET_PROPERTY_DESCARGABLE,
      payload: { key, value },
    });
  };

  const deleteLesson = (lesson_id, callback) => {
    LessonsService.deleteLesson(lesson_id).then(() => {
      success("¡Lección eliminada con éxito!");
      if (typeof callback === "function") callback();
      hideModal();
    });
  };

  const completeLesson = (lesson_id) => {
    LessonsService.completarLesson(lesson_id).then((res) => {
      success("¡Felicidades! Completaste una nueva lección.");
    });
  };

  const updateOrdenLessons = (lessons) => {
    LessonsService.putOrdenLessons(lessons);
  };

  const createMedia = () => {
    dispatch({ type: CREATE_MEDIA });
  };

  const setLessonMedia = (media) => {
    dispatch({ type: SET_LESSON_MEDIA, payload: media });
  };

  const clearLessonMedia = () => {
    dispatch({ type: SET_LESSON_MEDIA, payload: null });
  };

  const setLessonResource = (resource) => {
    dispatch({ type: SET_LESSON_RESOURCE, payload: resource });
  };

  const clearLessonResource = () => {
    dispatch({ type: SET_LESSON_RESOURCE, payload: null });
  };

  const postLessonMedia = (media) => {
    dispatch({ type: SHOW_SPINNER });
    const promises = [];
    if (typeof media.file === "object" && media.file !== null) {
      if (media.file.size) {
        let formData = AdjuntosService.getFormData(media.file);
        promises.push(
          new Promise((resolve, reject) => {
            AdjuntosService.postAdjunto(formData).then((res) => {
              const { file_id } = res.data;
              media.file_id = file_id;
              delete media.file;
              resolve();
            });
          })
        );
      }
    }
    Promise.all(promises)
      .then(() => {
        let service = LessonMediaService.putLessonMedia;
        if (isNaN(media.lesson_media_id)) {
          service = LessonMediaService.postLessonMedia;
        }
        service(media)
          .then(() => {
            hideModal();
            clearModal();
            success("Medio guardado.");
            getSingleLesson(media.lesson_id);
            dispatch({ type: HIDE_SPINNER });
          })
          .catch((error) => {
            dispatch({ type: SHOW_SPINNER });
            alert(error);
          });
      })
      .catch((error) => {
        dispatch({ type: SHOW_SPINNER });
        alert(error);
      });
  };

  const deleteLessonMedia = (lesson_media_id, callback) => {
    LessonMediaService.deleteLessonMedia(lesson_media_id).then(() => {
      success("Medio eliminado.");
      if (typeof callback === "function") {
        callback();
      }
    });
  };

  return (
    <LessonsContext.Provider
      value={{
        ...state,
        getLessons,
        postLesson,
        createMedia,
        clearLesson,
        sortLessons,
        clearLessons,
        selectLesson,
        createLesson,
        deleteLesson,
        setLessonMedia,
        completeLesson,
        postLessonMedia,
        getSingleLesson,
        clearLessonMedia,
        setPropertyMedia,
        getSectionLessons,
        setPropertyLesson,
        setLessonResource,
        deleteLessonMedia,
        updateOrdenLessons,
        getSiguienteLesson,
        eliminarMultimedia,
        eliminarDescargable,
        setPropertyResource,
        clearLessonResource,
        agregarMultimediaLesson,
        agregarDescargableLesson,
      }}
    >
      {children}
    </LessonsContext.Provider>
  );
};
