import React, { useState, useContext, useEffect } from "react";
import { CustomerContext } from "../../context/CustomerContext";
import PanelTitleDate from "../../components/global/PanelTitleDate";
import CourseAccessesUserTable from "../../components/course_accesses/CourseAccessUserTable";
import ToggleStatus from "../../components/global/ToggleStatus";

const statusOptions = [
  {
    name: "Todo",
    value: "",
  },
  {
    name: "Activos",
    value: "active",
  },
  {
    name: "Vencidas",
    value: "expired",
  },
];

const AdminCourseAccesses = () => {
  const [query, setQuery] = useState("");
  const [order, setOrder] = useState("ASC");
  const [status, setStatus] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");

  const { customers, getCustomerAccesses } = useContext(CustomerContext);

  useEffect(() => {
    fetchCourseAccesses();
  }, [startDate, endDate, order, query, status]);

  const updateDates = (start_date, end_date) => {
    setEndDate(end_date);
    setStartDate(start_date);
  };

  const fetchCourseAccesses = () => {
    getCustomerAccesses({
      start_date: startDate,
      end_date: endDate,
      status,
      query,
      order,
    });
  };

  return (
    <div className="container-fluid">
      <PanelTitleDate title="Alumnas" callback={updateDates} />
      <div className="card p-3 shadow">
        <div className="row align-items-center mb-3">
          <div className="col-12 col-md-6">
            <input
              type="text"
              value={query}
              className="form-control"
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Buscar alumnas por nombre o correo electrónico..."
            />
          </div>
          <div className="col-12 col-md-6">
            <ToggleStatus
              selected={status}
              modifier={setStatus}
              options={statusOptions}
            />
          </div>
        </div>
        <CourseAccessesUserTable
          order={order}
          setOrder={setOrder}
          course_accesses={customers}
          handleCallback={fetchCourseAccesses}
        />
      </div>
    </div>
  );
};

export default AdminCourseAccesses;
