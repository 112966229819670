import React, { createContext, useReducer, useContext } from "react";
import { QUESTIONS_RECEIVED, SET_QUESTION } from "../types/questions";
import {
  SET_UPDATE,
  ATTEMPTS_RECEIVED,
  SET_SINGLE_ATTEMPT,
  SET_PROPERTY_ATTEMPT,
} from "../types/attempts";
import {
  SET_ANSWER,
  SET_USER_ANSWER,
  ANSWERS_RECEIVED,
  SET_PROPERTY_USER_ANSWER,
} from "../types/answers";
import AttemptsReducer from "../reducers/AttemptsReducer";
import AttemptsService from "../services/AttemptsService";
import { SINGLE_TEST_RECEIVED } from "../types/tests";
import { ModalContext } from "./ModalContext";
import { navigate } from "@reach/router";
import FilesService from "../services/FilesService";
import { S3_ENDPOINT } from "../utils";

const initialState = {
  update: false,
  attempt: null,
  attempts: null,
  question: null,
  questions: null,
  user_answer: null,
};

export const AttemptsContext = createContext(initialState);

export const AttemptsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AttemptsReducer, initialState);

  const { clearModal, success } = useContext(ModalContext);

  const getUserAttempts = (user_id, filters) => {
    AttemptsService.getUserAttempts(user_id, filters).then((res) => {
      const { attempts } = res.data;
      dispatch({ type: ATTEMPTS_RECEIVED, payload: attempts });
    });
  };

  const getTestsAttempts = (test_id) => {
    AttemptsService.getTestsAttempts(test_id).then((res) => {
      const { attempts } = res.data;
      dispatch({ type: ATTEMPTS_RECEIVED, payload: attempts });
    });
  };

  const getFinalExamsAttempts = (test_id) => {
    AttemptsService.getFinalExamsAttempts(test_id).then((res) => {
      const { attempts } = res.data;
      dispatch({ type: ATTEMPTS_RECEIVED, payload: attempts });
    });
  };

  const getSingleAttempt = (attempt_id) => {
    AttemptsService.getSingleAttempt(attempt_id).then((res) => {
      const { attempt } = res.data;
      const { test, user_answers } = attempt;
      const { questions } = test;
      dispatch({ type: QUESTIONS_RECEIVED, payload: questions });
      dispatch({ type: SINGLE_TEST_RECEIVED, payload: test });
      dispatch({ type: ANSWERS_RECEIVED, payload: user_answers });
      dispatch({ type: SET_SINGLE_ATTEMPT, payload: attempt });
    });
  };

  const createAttempt = (test_id) => {
    AttemptsService.postAttempt(test_id).then((res) => {
      const { attempt } = res.data;
      success("Intento iniciado.");
      navigate(`/mytbm/attempt/${attempt.attempt_id}`);
      clearModal();
    });
  };

  const finishAttempt = (attempt_id) => {
    AttemptsService.finishAttempt(attempt_id)
      .then(() => {
        getSingleAttempt(attempt_id);
        success("Intento terminado");
        clearModal();
      })
      .catch(alert);
  };

  const saveUserAnswer = (user_answer, callback) => {
    let service = AttemptsService.putUserAnswer;
    if (isNaN(parseInt(user_answer.user_answer_id))) {
      service = AttemptsService.postUserAnswer;
    }
    service(user_answer).then(() => {
      success("Feedback guardado.");
      dispatch({ type: SET_USER_ANSWER, payload: null });
      dispatch({ type: SET_UPDATE, payload: true });
      if (typeof callback === "function") {
        callback();
      }
    });
  };

  const setQuestion = (question) => {
    dispatch({ type: SET_QUESTION, payload: question });
  };

  const setAnswer = (question_id, content) => {
    dispatch({ type: SET_ANSWER, payload: { question_id, content } });
  };

  const setUpdate = (update) => {
    dispatch({ type: SET_UPDATE, payload: update });
  };

  const clearAttempt = () => {
    dispatch({ type: SET_QUESTION, payload: null });
    dispatch({ type: ANSWERS_RECEIVED, payload: null });
    dispatch({ type: QUESTIONS_RECEIVED, payload: null });
    dispatch({ type: SET_SINGLE_ATTEMPT, payload: null });
    dispatch({ type: SINGLE_TEST_RECEIVED, payload: null });
  };

  const setUserAnswer = (user_answer) => {
    dispatch({ type: SET_USER_ANSWER, payload: user_answer });
  };

  const setPropertyAttempt = (key, value) => {
    dispatch({ type: SET_PROPERTY_ATTEMPT, payload: { key, value } });
  };

  const setPropertyUserAnswer = (key, value) => {
    dispatch({ type: SET_PROPERTY_USER_ANSWER, payload: { key, value } });
  };

  const saveAttempt = (attempt, callback) => {
    if (attempt.file && attempt.file !== null) {
      const formData = FilesService.getFormData(attempt.file);
      FilesService.postFile(formData).then((res) => {
        const { file_id } = res.data;
        attempt.file_id = file_id;
        AttemptsService.putAttempt(attempt).then(() => {
          getSingleAttempt(attempt.attempt_id);
          success("Intento actualizado.");
          if (typeof callback === "function") {
            callback();
          }
        });
      });
    } else if(typeof attempt.tracking_number === "object" || attempt.tracking_number.name) {
      const formData = FilesService.getFormData(attempt.tracking_number);
      FilesService.postFile(formData).then((res) => {
        const { file } = res.data;
        attempt.tracking_number = `${S3_ENDPOINT}/${file.name}.${file.type}`;
        AttemptsService.putAttempt(attempt).then(() => {
          getSingleAttempt(attempt.attempt_id);
          success("Intento actualizado.");
          if (typeof callback === "function") {
            callback();
          }
        });
      });
    } else {
      AttemptsService.putAttempt(attempt).then(() => {
        getSingleAttempt(attempt.attempt_id);
        success("Intento actualizado.");
        if (typeof callback === "function") {
          callback();
        }
      });
    }
  };

  const deleteAttempt = (attempt_id) => {
    AttemptsService.deleteAttempt(attempt_id)
      .then(() => {
        success("Intento terminado");
        navigate("/myadmin/tests");
        clearModal();
      })
      .catch(alert);
  };

  return (
    <AttemptsContext.Provider
      value={{
        ...state,
        getFinalExamsAttempts,
        setPropertyUserAnswer,
        setPropertyAttempt,
        getTestsAttempts,
        getSingleAttempt,
        getUserAttempts,
        saveUserAnswer,
        finishAttempt,
        createAttempt,
        setUserAnswer,
        deleteAttempt,
        clearAttempt,
        setQuestion,
        saveAttempt,
        setAnswer,
        setUpdate,
      }}
    >
      {children}
    </AttemptsContext.Provider>
  );
};
