import React from "react";
import { Router } from "@reach/router";
import AdminClientes from "./admin/AdminClientes";
import AdminCoaches from "./admin/AdminCoaches";
import AdminSingleUsuario from "./admin/AdminSingleUsuario";
import AdminDescuentos from "./admin/AdminDescuentos";
import AdminCustomerForm from "./admin/AdminCustomerForm";
import AdminUsuarios from "./admin/AdminUsuarios";
import AdminInvoices from "./admin/AdminInvoices";
import AdminPurchases from "./admin/AdminPurchases";
import AdminVideos from "./admin/AdminVideos";
import AdminCourses from "./admin/AdminCourses";
import AdminSingleCourse from "./admin/AdminSingleCourse";
import AdminSingleSection from "./admin/AdminSingleSection";
import AdminSingleLesson from "./admin/AdminSingleLesson";
import AdminSingleAttempt from "./admin/AdminSingleAttempt";
import AdminFinalTests from "./admin/AdminFinalTests";
import AdminCourseAccesses from "./admin/AdminCourseAccesses";
import AdminProducts from "./admin/AdminProducts";
import AdminSingleProduct from "./admin/AdminSingleProduct";
import AdminLocations from "./admin/AdminLocations";

const Admin = () => {
  return (
    <Router>
      <AdminVideos path="/videos" />
      <AdminUsuarios path="/users" />
      <AdminCourses path="/courses" />
      <AdminFinalTests path="/tests" />
      <AdminProducts path="/products" />
      <AdminLocations path="/locations" />
      <AdminClientes path="/customers" />
      <AdminCoaches path="/instructors" />
      <AdminDescuentos path="/descuentos" />
      <AdminInvoices path="/subscriptions" />
      <AdminCourseAccesses path="/alumnas" />
      <AdminPurchases path="/orders" default />
      <AdminSingleLesson path="/lessons/:lesson_id" />
      <AdminSingleCourse path="/courses/:course_id" />
      <AdminSingleSection path="/sections/:section_id" />
      <AdminSingleProduct path="/products/:product_id" />
      <AdminSingleUsuario path="/customer/:customer_id" />
      <AdminCustomerForm path="/customer/:customer_id/edit" />
      <AdminSingleAttempt path="/customer/:customer_id/attempts/:attempt_id" />
    </Router>
  );
};

export default Admin;
