import React, { useContext } from "react";
import { CourseAccessContext } from "../../context/CourseAccessContext";
import CourseAccessForm from "./CourseAccessForm";
import { ModalContext } from "../../context/ModalContext";
import { UserContext } from "../../context/UserContext";
import { Link } from "@reach/router";
import moment from "moment";
import { CustomerContext } from "../../context/CustomerContext";
import ConfirmDeleteAccess from "./ConfirmDeleteAccess";

const CourseAccessRow = ({ course_access, handleCallback }) => {
  const { user } = useContext(UserContext);
  const { customer } = useContext(CustomerContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  const { setCourseAccess, deleteCourseAccess } =
    useContext(CourseAccessContext);

  const handleCancel = () => {
    setCourseAccess(null);
    clearModal();
  };

  const handleEdit = () => {
    setCourseAccess(course_access);
    modalComponent(
      "Editar Compra",
      <CourseAccessForm
        purchases={customer.purchases}
        handleCancel={handleCancel}
      />
    );
  };

  const handleDelete = () => {
    modalComponent(
      "Eliminar Acceso",
      <ConfirmDeleteAccess
        handleDelete={deleteCourseAccess}
        handleCallback={handleCallback}
        course_access={course_access}
        handleCancel={handleCancel}
        customer={customer}
      />
    );
  };

  const canEdit = () => {
    return ["admin", "manager"].includes(user.role);
  };

  const renderActions = () => {
    if (canEdit()) {
      return (
        <div>
          <button
            className="btn btn-sm btn-outline-dark me-2"
            onClick={handleEdit}
          >
            <i className="fa fa-edit"></i>
          </button>
          <button
            className="btn btn-sm btn-outline-danger me-2"
            onClick={handleDelete}
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      );
    }
  };

  const renderCustomer = () => {
    if (course_access.user && course_access.user !== null) {
      return (
        <td>
          <Link to={`/myadmin/customer/${course_access.user.user_id}`}>
            <i className="fa fa-eye"></i> {course_access.user.name}{" "}
            {course_access.user.last_name}
          </Link>
        </td>
      );
    }
  };

  const renderCourse = () => {
    if (course_access.course && course_access.course !== null) {
      return course_access.course.name;
    }
  };

  const renderExpiration = () => {
    if (course_access.expiration_date === null) {
      return "Sin expiración";
    }
    return moment(course_access.expiration_date)
      .utc()
      .endOf("day")
      .format("DD MMM YYYY HH:mm");
  };

  const renderFinalExam = () => {
    if (course_access.final_exam && course_access.final_exam !== null) {
      return course_access.final_exam;
    }
  };

  return (
    <tr className="small border-bottom align-middle hover-light">
      <td>
        {course_access.course_access_id}{" "}
        {course_access.enabled_by !== null && (
          <i className="fa fa fa-user-shield"></i>
        )}
      </td>
      {renderCustomer()}
      <td>{renderCourse()}</td>
      <td>{renderFinalExam()}</td>
      <td>{renderExpiration()}</td>
      <td>{renderActions()}</td>
    </tr>
  );
};

export default CourseAccessRow;
