import { SET_course_access } from "../types";
import {
  CREATE_COURSE_ACCESS,
  SET_PROPERTY_COURSE_ACCESS,
} from "../types/course_access";

const schema = {
  product_id: null,
  purchase_id: null,
  expiration_date: null,
};

const CourseAccessReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_course_access:
      return { ...state, course_access: payload };
    case CREATE_COURSE_ACCESS:
      return { ...state, course_access: schema };
    case SET_PROPERTY_COURSE_ACCESS:
      const course_access = { ...state.course_access };
      const { key, value } = payload;
      course_access[key] = value;
      return { ...state, course_access };
    default:
      return { ...state };
  }
};
export default CourseAccessReducer;
