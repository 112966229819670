import React, { useEffect } from "react";

const SingleCourseProductForm = ({
  product,
  courses,
  modifier,
  handleDelete,
  course_product,
}) => {
  useEffect(() => {
    if (course_product && course_product !== null && Array.isArray(courses)) {
      if (course_product.course_id === "") {
        const firstCourse = courses[0];
        modifier(course_product, "course_id", firstCourse.course_id);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCoursesOptions = (course_product) => {
    if (Array.isArray(courses)) {
      let course_products = product.course_products.map(
        ({ course_id }) => course_id
      );
      let coursesRender = courses.filter(
        ({ course_id }) =>
          (!course_products.includes(course_id) ||
            course_id === course_product.course_id)
      );
      return coursesRender.map((course) => (
        <option key={course.course_id} value={course.course_id}>
          {course.name}
        </option>
      ));
    }
  };
  return (
    <div className="row align-items-center mb-3">
      <div className="col">
        <label>Certificación</label>
        <select
          value={course_product.course_id}
          className="form-control"
          onChange={(e) => {
            modifier(course_product, "course_id", e.target.value);
          }}
        >
          {renderCoursesOptions(course_product)}
        </select>
      </div>
      <div className="col">
        <label>Días de Acceso</label>
        <input
          type="number"
          className="form-control"
          value={course_product.expiration_days}
          onChange={(e) => {
            modifier(course_product, "expiration_days", e.target.value);
          }}
        />
      </div>
      <div className="col">
        <label style={{ visibility: "hidden" }} className="d-block">
          Acciones
        </label>
        <button
          onClick={() => handleDelete(course_product)}
          className="btn btn-sm btn-outline-danger"
          type="button"
        >
          <i className="fa fa-trash"></i>
        </button>
      </div>
    </div>
  );
};

export default SingleCourseProductForm;
