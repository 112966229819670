import React, { useState, useContext } from "react";
import { S3_ENDPOINT } from "../../utils";
import { AttemptsContext } from "../../context/AttemptsContext";

const AttemptCertificate = ({ type }) => {
  const [file, setFile] = useState(null);

  const { attempt, saveAttempt, setPropertyAttempt } =
    useContext(AttemptsContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    saveAttempt({ ...attempt, file });
  };

  const renderCertificate = () => {
    if (attempt.certificate !== null) {
      return (
        <div>
          <a
            href={`${S3_ENDPOINT}/${attempt.certificate.name}.${attempt.certificate.type}`}
            className="btn px-0 btn-link mb-3"
            target="_blank"
          >
            Ver {type}
          </a>
          <button
            onClick={() => setPropertyAttempt("certificate", null)}
            className="btn d-block btn-outline-dark"
          >
            <i className="fa fa-upload me-1"></i> Cambiar Archivo
          </button>
        </div>
      );
    }
    return (
      <form onSubmit={handleSubmit}>
        <input
          type="file"
          className="form-control mb-3"
          onChange={(e) => setFile(e.target.files[0])}
        />
        <button type="submit" className="btn btn-primary">
          <i className="fa fa-upload me-2"></i> Subir Archivo
        </button>
      </form>
    );
  };

  return (
    <div>
      <h3>{type} Digital</h3>
      {renderCertificate()}
    </div>
  );
};

export default AttemptCertificate;
