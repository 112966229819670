import React, { useContext } from "react";
import { LocationsContext } from "../../context/LocationsContext";
import AddressForm from "../address/AddressForm";
import { getValue } from "../../utils";
import { AddressContext } from "../../context/AddressContext";
import ReactSwitch from "react-switch";

const LocationForm = ({ handleCancel }) => {
  const { location, postLocation, setPropiedadLocation } =
    useContext(LocationsContext);
  const { postAddress } = useContext(AddressContext);

  const handleSubmit = (address) => {
    postAddress(address, handleCallback);
  };

  const handleCallback = (address) => {
    postLocation({ ...location, address_id: address.address_id });
  };

  const renderForm = () => {
    if (location && location !== null) {
      return (
        <div>
          <label>Nombre</label>
          <input
            type="text"
            className="form-control mb-3"
            value={getValue(location, "name")}
            onChange={(e) => setPropiedadLocation("name", e.target.value)}
          />
          <div className="row mb-3">
            <div className="col-6">
              <label className="bold">Mostrar Nombre</label>
            </div>
            <div className="col-6 text-end">
              <ReactSwitch
                onChange={(checked) =>
                  setPropiedadLocation("show_location_name", checked)
                }
                checked={getValue(location, "show_location_name", "boolean")}
              />
            </div>
          </div>
          <AddressForm handleCancel={handleCancel} saveAction={handleSubmit} />
        </div>
      );
    }
  };

  return <div className="container-fluid px-0">{renderForm()}</div>;
};

export default LocationForm;
