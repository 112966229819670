import React from "react";
import CourseAccessRow from "./CourseAccessRow";

const CourseAccessesTable = ({
  customer_id,
  course_accesses,
  handleCallback,
}) => {
  const renderPurchases = () => {
    if (Array.isArray(course_accesses)) {
      if (course_accesses.length === 0) {
        return (
          <tr>
            <td colSpan={5}>No hay cursos registradas.</td>
          </tr>
        );
      }
      return course_accesses.map((course_access) => (
        <CourseAccessRow
          customer_id={customer_id}
          handleCallback={handleCallback}
          key={course_access.course_access_id}
          course_access={course_access}
        />
      ));
    }
  };

  const renderCustomer = () => {
    if (!window.location.pathname.includes("customer/")) {
      return <td>Cliente</td>;
    }
  };

  return (
    <div className="table-responsive">
      <table className="table border">
        <thead className="bg-light border bold small">
          <tr>
            <td>#ID</td>
            {renderCustomer()}
            <td>Certificación</td>
            <td>Examen Final</td>
            <td>Expiración</td>
            <td>Acciones</td>
          </tr>
        </thead>
        <tbody>{renderPurchases()}</tbody>
      </table>
    </div>
  );
};

export default CourseAccessesTable;
