import React, { useContext, useState } from "react";
import AddressForm from "../address/AddressForm";
import AddressData from "../address/AddressData";
import { AddressContext } from "../../context/AddressContext";
import { CustomerContext } from "../../context/CustomerContext";

const CustomerAddress = ({ customer_id }) => {
  const [editMode, setEditMode] = useState(false);
  const { setAddress, postAddress } = useContext(AddressContext);
  const { customer, getCustomer } = useContext(CustomerContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    setEditMode(false);
    postAddress(customer);
  };

  const handleCancel = () => {
    setEditMode(false);
    getCustomer(customer_id);
  };

  const handleEdit = () => {
    setEditMode(true);
    setAddress(customer.address);
  };

  const renderInformacion = () => {
    if (customer && customer !== null) {
      if (editMode) {
        return (
          <AddressForm
            customer={customer}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            handleCallback={handleCancel}
          />
        );
      }
      if (customer.address && customer.address !== null) {
        return (
          <AddressData address={customer.address} handleEdit={handleEdit} />
        );
      }
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="container-fluid px-0 mb-3">
      <h4 className="border-bottom pb-2 mb-2">Dirección</h4>
      {renderInformacion()}
    </div>
  );
};

export default CustomerAddress;
