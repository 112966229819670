import React, { useEffect, useState } from "react";

const ImageInput = ({ prop, value, modifier, multiple, base_url }) => {
  const [picture, setPicture] = useState(null);
  const [src, setSrc] = useState("");

  useEffect(() => {
    if (value && value !== null) {
      if (typeof value === "string") {
        setSrc(value);
      } else if (typeof value === "object") {
        setSrc(`${base_url}/${value.name}.${value.type}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (picture !== null && src === "") {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSrc(e.target.result);
      };
      reader.readAsDataURL(picture);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [picture]);

  const handleFiles = (e) => {
    const files = e.target.files;
    if (multiple) {
      modifier(prop, files);
    } else {
      const currentFile = files[0];
      setPicture(currentFile);
      modifier(prop, currentFile);
    }
  };

  const renderImage = () => {
    if (src && src !== null && src !== "") {
      return (
        <img
          alt="Vista Previa Video"
          src={src}
          className="video-form-thumbnail mw-100 d-block"
        />
      );
    }
  };

  const renderImageForm = () => {
    if (src === "") {
      return (
        <div className="container-fluid">
          <label>Thumbnail</label>
          <input
            type="file"
            className="form-control mb-3"
            onChange={handleFiles}
          />
        </div>
      );
    }
    return (
      <>
        <div className="col-6">{renderImage()}</div>
        <div className="col-6">
          <button
            className="btn btn-outline-dark d-block"
            onClick={() => setSrc("")}
          >
            <i className="fa fa-camera me-2"></i> Cambiar Imagen
          </button>
        </div>
      </>
    );
  };

  return <div className="row my-3">{renderImageForm()}</div>;
};

export default ImageInput;
