import Paragraph from "@editorjs/paragraph";
import List from "@editorjs/list";
import Header from "@editorjs/header";
import Quote from "@editorjs/quote";
import Marker from "@editorjs/marker";
import HTMLReactParser from "html-react-parser";

export const EDITOR_JS_TOOLS = {
  paragraph: Paragraph,
  list: List,
  header: Header,
  quote: Quote,
  marker: Marker,
};

const renderListItems = (items) =>
  items.map((item) => `<li>${item}</li>`).join("");

export const parseBlock = (block) => {
  if (block.type === "list") {
    if (block.data.style === "ordered") {
      return `<ol>${renderListItems(block.data.items)}</ol>`;
    } else {
      return `<ul>${renderListItems(block.data.items)}</ul>`;
    }
  }
  if (block.type === "quote") {
    return `<div ${
      block.data.alignment === "center" ? "className='text-center'" : ""
    }><quote>${block.data.text}</quote><p>${block.data.caption}</p></div>`;
  }
  if (block.type === "header") {
    return `<h${block.data.level}>${block.data.text}</h${block.data.level}>`;
  }
  if (block.type === "paragraph") {
    return `<p>${block.data.text}</p>`;
  }
};

export const htmlToBlocks = (html) => {
  const htmlBlocks = HTMLReactParser(html);
  if (!Array.isArray(htmlBlocks)) {
    return [{ type: "paragraph", data: { text: htmlBlocks } }];
  }
  const blocks = [];
  htmlBlocks.forEach((block) => {
    if (block.type === "p") {
      blocks.push({ type: "paragraph", data: { text: block.props.children } });
    } else if (block.type[0] === "h") {
      const level = block.type[1];
      blocks.push({
        type: "header",
        data: {
          text: block.props.children,
          level,
        },
      });
    } else if (block.type === "ol") {
      blocks.push({
        type: "list",
        data: {
          style: "ordered",
          items: block.props.children.map(({ props }) => {
            if (Array.isArray(props.children)) {
              return props.children[0];
            }
            return props.children;
          }),
        },
      });
    } else if (block.type === "ul" && Array.isArray(block.props.children)) {
      blocks.push({
        type: "list",
        data: {
          style: "unordered",
          items: block.props.children.map(({ props }) => props.children),
        },
      });
    }
  });
  return blocks;
};
