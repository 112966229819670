import React from "react";
import { Link } from "@reach/router";

const CourseRow = ({ course }) => {
  return (
    <tr className="small bg-white border-bottom align-middle hover-light">
      <td>#{course.course_id}</td>
      <td>
        <Link to={`/myadmin/courses/${course.course_id}`}>{course.name}</Link>
      </td>
      <td>{course.course_accesses}</td>
      <td>{course.active_course_accesses}</td>
    </tr>
  );
};

export default CourseRow;
