import React, { useContext, useEffect } from "react";
import { CustomerContext } from "../../context/CustomerContext";
import { ModalContext } from "../../context/ModalContext";
import { UserContext } from "../../context/UserContext";
import PurchasesTable from "../../components/purchases/PurchasesTable";
import InvoicesTable from "../../components/invoices/InvoicesTable";
import PanelTitle from "../../components/global/PanelTitle";
import AddPurchaseForm from "../../components/purchases/AddPurchaseForm";
import CourseAccessesTable from "../../components/course_accesses/CourseAccessTable";
import InvoiceForm from "../../components/invoices/InvoiceForm";
import { InvoicesContext } from "../../context/InvoicesContext";
import { CoursesContext } from "../../context/CoursesContext";
import CustomerData from "../../components/customers/CustomerData";
import CustomerActions from "../../components/customers/CustomerActions";
import { CourseAccessContext } from "../../context/CourseAccessContext";
import CourseAccessForm from "../../components/course_accesses/CourseAccessForm";
import SectionTestsAttemps from "../../components/tests/SectionTestsAttemps";
import CourseTestsAttemps from "../../components/tests/CourseTestsAttempts";
import CompletedCourses from "../../components/courses/CompletedCourses";
import CompletedCourseForm from "../../components/courses/CompletedCourseForm";
import CustomerAddress from "../../components/customers/CustomerAddress";
import { navigate } from "@reach/router";
import { hideModal } from "../../utils";

const AdminSingleUsuario = ({ customer_id }) => {
  const {
    customer,
    getCustomer,
    clearCustomer,
    extenderAcceso,
    deleteCustomer,
  } = useContext(CustomerContext);

  const { user } = useContext(UserContext);

  const { createInvoice } = useContext(InvoicesContext);

  const { setCourseAccess, createCourseAccess, setPropertyCourseAccess } =
    useContext(CourseAccessContext);
  const { courses, getCourses } = useContext(CoursesContext);

  const { clearModal, modalComponent } = useContext(ModalContext);

  useEffect(() => {
    clearCustomer();
    fetchCustomer();
    getCourses();
    return clearCustomer;
  }, [customer_id]);

  const fetchCustomer = () => {
    getCustomer(customer_id);
  };

  const handleCancel = () => {
    clearModal();
    setCourseAccess(null);
  };

  const addInvoice = () => {
    createInvoice();
    modalComponent("Agregar Cargo", <InvoiceForm />);
  };

  const addCompletedCourse = () => {
    modalComponent(
      "Agregar Certificación",
      <CompletedCourseForm
        user_id={customer_id}
        handleCancel={clearModal}
        handleCallback={fetchCustomer}
      />
    );
  };

  const toggleModal = () => {
    modalComponent(
      "Agregar Compra",
      <AddPurchaseForm
        paquetes={courses}
        customer={customer}
        handleCancel={clearModal}
        extenderAcceso={extenderAcceso}
      />
    );
  };

  const addAccess = () => {
    createCourseAccess();
    setPropertyCourseAccess("user_id", customer.user_id);
    modalComponent(
      "Agregar Acceso",
      <CourseAccessForm
        purchases={customer.purchases}
        handleCancel={handleCancel}
      />
    );
  };

  const confirmDelete = () => {
    modalComponent(
      "Precaución",
      <div>
        <p>
          ¿Estás segura que deseas eliminar el cliente {customer.name}{" "}
          {customer.last_name} con el correo {customer.email}?
        </p>
        <p>Esta acción NO puede deshacerse.</p>
        <div className="row">
          <div className="col-6">
            <button
              className="btn btn-danger"
              onClick={() => deleteCustomer(customer_id)}
            >
              <i className="fa fa-trash"></i> Eliminar Cliente
            </button>
          </div>
          <div className="col-6 text-right">
            <button className="btn btn-link text-secondary" onClick={hideModal}>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderUsuario = () => {
    if (customer && customer !== null) {
      return (
        <div className="row">
          <div className="col-12 col-md-6">
            <CustomerData
              customer={customer}
              handleEdit={() =>
                navigate(`/myadmin/customer/${customer_id}/edit`)
              }
            />
          </div>
          <div className="col-12 col-md-6">
            <CustomerAddress customer_id={customer_id} />
            <CustomerActions customer={customer} />
          </div>
        </div>
      );
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="container-fluid">
      <div className="row pb-2 border-bottom mx-0 mb-3 align-items-center">
        <div className="col col-md-6 ps-0">
          <h1>Cliente</h1>
        </div>
        <div className="col col-md-6 pe-0 text-end"></div>
      </div>
      <div className="card no-scale mb-3">{renderUsuario()}</div>
      {["admin", "manager"].includes(user.role) && (
        <div className="card no-scale p-3">
          <PanelTitle title="Accesos" onClick={addAccess} />
          <CourseAccessesTable
            customer_id={customer_id}
            handleCallback={fetchCustomer}
            course_accesses={customer !== null ? customer.course_accesses : []}
          />
        </div>
      )}
      {["admin", "manager"].includes(user.role) && (
        <div className="card p-3 pb-1 no-scale my-3">
          <PanelTitle title="Compras" onClick={toggleModal} />
          <PurchasesTable
            user={user}
            purchases={customer !== null ? customer.purchases : []}
          />
        </div>
      )}
      {["admin", "manager"].includes(user.role) && (
        <div className="card p-3 pb-1 no-scale my-3">
          <PanelTitle title="Cargos" onClick={addInvoice} />
          <InvoicesTable
            user={user}
            customer_id={customer_id}
            invoices={customer !== null ? customer.invoices : []}
          />
        </div>
      )}
      <div className="card p-3 pb-1 no-scale my-3">
        <PanelTitle
          title="Certificaciones Completadas"
          onClick={addCompletedCourse}
        />
        <CompletedCourses
          user_id={customer_id}
          handleCallback={fetchCustomer}
          courses={customer !== null ? customer.completed_courses : []}
        />
      </div>
      {["admin", "manager"].includes(user.role) && (
        <div className="card p-3 pb-1 no-scale my-3">
          <SectionTestsAttemps customer_id={customer_id} />
        </div>
      )}
      <div className="card p-3 pb-1 no-scale my-3">
        <CourseTestsAttemps customer_id={customer_id} />
      </div>
      <button className="btn btn-outline-danger mt-5" onClick={confirmDelete}>
        <i className="fa fa-trash"></i> Eliminar Cliente
      </button>
    </div>
  );
};

export default AdminSingleUsuario;
