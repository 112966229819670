import api from "./api";

const route = "/addresses";

const AddressService = {
  getAddresses: () => api.get(route),
  postAddress: (address) => api.post(route, { ...address }),
  putAddress: (address) => api.put(route, { ...address }),
  deleteAddress: (address_id) => api.delete(`${route}/${address_id}`),
};
export default AddressService;
