import React, { useState, useContext, useEffect } from "react";
import { ProductsContext } from "../../context/ProductsContext";
import {
  parseBlock,
  htmlToBlocks,
  EDITOR_JS_TOOLS,
} from "../common/EditorTools";
import EditorJS from "react-editor-js";
import ReactSwitch from "react-switch";
import CourseProductForm from "./CourseProductForm";
import { S3_ENDPOINT, getValue, grayImg, parseStringHTML } from "../../utils";
import ProductPaymentPlans from "./ProductPaymentPlans";
import { getLocationName } from "../../utils/location";
import { LocationsContext } from "../../context/LocationsContext";

const ProductForm = ({ handleCancel, handleCallback }) => {
  const [descBlocks, setDescBlocks] = useState(null);
  const [descInstance, setDescInstance] = useState(null);
  const [shortDescBlocks, setShortDescBlocks] = useState(null);
  const [shortDescInstance, setShortDescInstance] = useState(null);

  const { locations, getLocations } = useContext(LocationsContext);
  const { product, saveProduct, setPropertyProduct, clearProduct } =
    useContext(ProductsContext);

  useEffect(() => {
    getLocations();

    return () => {
      clearProduct();
    }
  }, []);

  useEffect(() => {
    if (product && product !== null) {
      if (descBlocks === null) {
        formatStringBlocks(product.description, setDescBlocks);
      }
      if (shortDescBlocks === null) {
        formatStringBlocks(product.short_description, setShortDescBlocks);
      }
      if (getValue(product, "location_id") === "") {
        if (Array.isArray(locations) && locations.length > 0) {
          const first = locations[0];
          setPropertyProduct("location_id", first.location_id);
        }
      }
    }
  }, [product, locations]);

  const formatStringBlocks = (text, callback) => {
    if (String(text).includes("<")) {
      text = parseStringHTML(text);
      let blocks = htmlToBlocks(text);
      callback(blocks);
    } else {
      callback([]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let data = await descInstance.save();
    let content = "";
    data.blocks.forEach((block) => {
      content += parseBlock(block);
    });
    product.description = content;

    data = await shortDescInstance.save();
    content = "";
    data.blocks.forEach((block) => {
      content += parseBlock(block);
    });
    product.short_description = content;

    saveProduct(product, handleCallback);
  };

  const renderLocations = () => {
    if (Array.isArray(locations)) {
      return locations.map((location) => (
        <option key={location.location_id} value={location.location_id}>
          {getLocationName(location)}
        </option>
      ));
    }
  };

  const renderLive = () => {
    if (getValue(product, "mode") === "live") {
      return (
        <div>
          <div className="row mb-3">
            <div className="col-6">
              <label>Precio para Separar Lugar</label>
              <input
                type="number"
                className="form-control"
                value={getValue(product, "separate_price")}
                onChange={(e) =>
                  setPropertyProduct("separate_price", e.target.value)
                }
              />
            </div>
            <div className="col-6">
              <label>Ubicación</label>
              <select
                value={getValue(product, "location_id")}
                className="form-control"
                onChange={(e) =>
                  setPropertyProduct("location_id", e.target.value)
                }
              >
                {renderLocations()}
              </select>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-6">
              <label>Fecha Inicio</label>
              <input
                type="date"
                className="form-control"
                value={getValue(product, "start_date", "date")}
                onChange={(e) =>
                  setPropertyProduct("start_date", e.target.value)
                }
              />{" "}
            </div>
            <div className="col-6">
              <label>Fecha Fin</label>
              <input
                type="date"
                className="form-control"
                value={getValue(product, "end_date", "date")}
                onChange={(e) => setPropertyProduct("end_date", e.target.value)}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-6">
              <label>Capacidad</label>
              <input
                type="number"
                className="form-control"
                value={getValue(product, "capacity")}
                onChange={(e) => setPropertyProduct("capacity", e.target.value)}
              />
            </div>
            <div className="col-6">
              <label>Compras por Cliente</label>
              <input
                type="number"
                className="form-control"
                value={getValue(product, "limit_per_user")}
                onChange={(e) =>
                  setPropertyProduct("limit_per_user", e.target.value)
                }
              />
            </div>
          </div>
        </div>
      );
    }
  };

  const renderDescriptionForm = () => {
    if (Array.isArray(descBlocks)) {
      return (
        <EditorJS
          data={{ blocks: descBlocks }}
          instanceRef={(current) => setDescInstance(current)}
          tools={EDITOR_JS_TOOLS}
        />
      );
    }
  };

  const rendeShortDescriptionForm = () => {
    if (Array.isArray(shortDescBlocks)) {
      return (
        <EditorJS
          data={{ blocks: shortDescBlocks }}
          instanceRef={(current) => setShortDescInstance(current)}
          tools={EDITOR_JS_TOOLS}
        />
      );
    }
  };

  const getProductImageSrc = () => {
    const newImageSrc = getValue(product, "new_file_src");
    const currentFile = getValue(product, "file");
    const currentFileSrc = `${currentFile?.name}.${currentFile?.type}`;
    
    if(newImageSrc) {
      return newImageSrc;
    } else if(currentFile && currentFileSrc) {
      return `${S3_ENDPOINT}/${currentFileSrc}`;
    } else {
      return grayImg;
    }

  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="row align-items-center">
        <div className="col-12 col-md-6">
          <label>Name</label>
          <input
            type="text"
            className="form-control mb-3"
            value={getValue(product, "name")}
            onChange={(e) => setPropertyProduct("name", e.target.value)}
          />
        </div>
        <div className="col-12 col-md-6">
          <div className="row">
            <div className="col-6">
              <label>Disponible</label>
            </div>
            <div className="col-6 text-right">
              <ReactSwitch
                checked={getValue(product, "available", "boolean")}
                onChange={(checked) => setPropertyProduct("available", checked)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-6">
          <label>Precio</label>
          <input
            type="number"
            className="form-control"
            value={getValue(product, "price")}
            onChange={(e) => setPropertyProduct("price", e.target.value)}
          />
        </div>
        <div className="col-6">
          <label>Oferta</label>
          <input
            type="number"
            className="form-control"
            value={getValue(product, "sale_price")}
            onChange={(e) => setPropertyProduct("sale_price", e.target.value)}
          />
        </div>
      </div>

      <ProductPaymentPlans />

      <div className="row mb-3">
        <div className="col-6">
          <button
            className={`btn w-100 ${
              getValue(product, "mode") === "on-demand"
                ? "btn-primary text-dark"
                : "btn-outline-secondary"
            }`}
            onClick={() => setPropertyProduct("mode", "on-demand")}
            type="button"
          >
            On Demand
          </button>
        </div>
        <div className="col-6">
          {" "}
          <button
            className={`btn w-100 ${
              getValue(product, "mode") === "live"
                ? "btn-accent text-dark"
                : "btn-outline-secondary"
            }`}
            onClick={() => setPropertyProduct("mode", "live")}
            type="button"
          >
            Live
          </button>
        </div>
      </div>

      {renderLive()}

      <CourseProductForm />

      <h3>Información</h3>
      <div className="row">
        <div className="col-12 col-md-6">
          <label>Descripción</label>
          {renderDescriptionForm()}
        </div>
        <div className="col-12 col-md-6">
          <label>Descripción Corta</label>
          {rendeShortDescriptionForm()}
        </div>
      </div>


      <h3>Imagen</h3>
      <div className="row mb-5">
        <div className="col-12">
          <img
            src={getProductImageSrc()}
            alt="product image"
            className="bg-gray mb-3"
            style={{
              width: "500px",
              height: "auto",
              maxHeight: "400px",
              minHeight: "300px",
              objectFit: "cover",
            }}
          />
        </div>

        <div className="col-12">
          <input
            type="file"
            className="form-control"
            style={{ width: "max-content" }}
            onChange={(event) => {
              const reader = new FileReader();
              const image = event.target.files[0];

              if (image) {
                reader.onload = (e) => {
                  const src = e.target.result;
                  setPropertyProduct("new_file_src", src);
                  setPropertyProduct("new_file", image);
                };

                reader.readAsDataURL(image);
              }
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <button
            type="button"
            onClick={handleCancel}
            className="btn text-muted w-100"
          >
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button type="submit" className="btn btn-primary w-100">
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
};

export default ProductForm;
