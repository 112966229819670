import React, { useContext, useEffect } from "react";
import PanelTitle from "../../components/global/PanelTitle";
import ProductForm from "../../components/products/ProductForm";
import { ProductsContext } from "../../context/ProductsContext";
import { navigate } from "@reach/router";

const AdminSingleProduct = ({ product_id }) => {
  const { product, createProduct, getSingleProduct } =
    useContext(ProductsContext);

  useEffect(() => {
    if (isNaN(parseInt(product_id))) {
      createProduct();
    } else {
      getSingleProduct(product_id);
    }
  }, [product_id]);

  const handleCallback = () => navigate("/myadmin/products");

  const renderForm = () => {
    if (product && product !== null) {
      return (
        <ProductForm
          handleCancel={handleCallback}
          handleCallback={handleCallback}
        />
      );
    }
  };

  return (
    <div className="container-fluid">
      <PanelTitle title="Producto" hideButton />
      <div className="card p-3 shadow-sm">{renderForm()}</div>
    </div>
  );
};

export default AdminSingleProduct;
