import React from "react";

const Footer = () => {
  return (
    <div className="footer container-fluid bg-dark py-3 text-center text-white">
      <div className="row">
        <div className="col-12 col-md-4 mobile-center my-2">
          &copy; 2022 The Body Method
        </div>
        <div className="col-12 col-md-4 text-center my-2"></div>
        <div className="col-12 col-md-4 mobile-center text-end my-2"></div>
      </div>
    </div>
  );
};

export default Footer;
