import React, { useState, useContext, useEffect } from "react";
import { CoursesContext } from "../../context/CoursesContext";

const CompletedCourseForm = ({ user_id, handleCancel, handleCallback }) => {
  const [selected, setSelected] = useState(null);
  const { courses, getCourses, completeCourse } = useContext(CoursesContext);

  useEffect(() => {
    getCourses();
  }, []);

  useEffect(() => {
    if (Array.isArray(courses)) {
      if (courses.length > 0) {
        setSelected(courses[0].course_id);
      }
    }
  }, [courses]);

  const handleSubmit = (e) => {
    e.preventDefault();
    completeCourse(selected, user_id, handleCallback);
  };

  const renderCourses = () => {
    if (Array.isArray(courses)) {
      return courses.map((course) => (
        <option key={course.course_id} value={course.course_id}>
          {course.name}
        </option>
      ));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>Elige una Certificación</label>
      <select
        value={selected}
        className="form-control mb-3"
        onChange={(e) => setSelected(e.target.value)}
      >
        {renderCourses()}
      </select>
      <div className="row">
        <div className="col-6">
          <button
            type="button"
            onClick={handleCancel}
            className="btn text-muted w-100"
          >
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button className="btn btn-primary w-100">Agregar</button>
        </div>
      </div>
    </form>
  );
};

export default CompletedCourseForm;
