import React from "react";
import { CoachesProvider } from "./context/CoachesContext";
import { CustomerProvider } from "./context/CustomerContext";
import { ModalProvider } from "./context/ModalContext";
import { PurchaseProvider } from "./context/PurchasesContext";
import { UserProvider } from "./context/UserContext";
import { DiscountsProvider } from "./context/DiscountsContext";
import { AnaliticasProvider } from "./context/AnaliticasContext";
import { InvoicesProvider } from "./context/InvoicesContext";
import { PaymentMethodsProvider } from "./context/PaymentMethodsContext";
import { CourseAccessProvider } from "./context/CourseAccessContext";
import { CoursesProvider } from "./context/CoursesContext";
import { combineComponents } from "./context";
import Main from "./Main";
import "./index.css";
import { SectionsProvider } from "./context/SectionsContext";
import { LessonsProvider } from "./context/LessonsContext";
import { StaffProvider } from "./context/StaffContext";
import { ProductsProvider } from "./context/ProductsContext";
import { AttemptsProvider } from "./context/AttemptsContext";
import { AddressProvider } from "./context/AddressContext";
import { PaymentPlansProvider } from "./context/PaymentPlansContext";
import { LocationsProvider } from "./context/LocationsContext";

const AppContextProvider = combineComponents([
  ModalProvider,
  UserProvider,
  StaffProvider,
  AddressProvider,
  LessonsProvider,
  CoursesProvider,
  CoachesProvider,
  InvoicesProvider,
  CustomerProvider,
  PurchaseProvider,
  SectionsProvider,
  ProductsProvider,
  AttemptsProvider,
  LocationsProvider,
  DiscountsProvider,
  AnaliticasProvider,
  CourseAccessProvider,
  PaymentPlansProvider,
  PaymentMethodsProvider,
]);

function App() {
  return (
    <AppContextProvider>
      <Main />
    </AppContextProvider>
  );
}

export default App;
