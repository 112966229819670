import React from "react";
import LessonCard from "./LessonCard";

const LessonsGrid = ({ lessons, handleEdit, handleDelete }) => {
  const renderLessons = () => {
    if (Array.isArray(lessons)) {
      if (lessons.length === 0) {
        return <p>No hay contenido para esta sección.</p>;
      }
      return lessons.map((lesson) => (
        <LessonCard
          lesson={lesson}
          key={lesson.lesson_id}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />
      ));
    }
  };

  return <div className="row mx-0">{renderLessons()}</div>;
};

export default LessonsGrid;
