import React, { useContext } from "react";
import moment from "moment";
import ProductMode from "./ProductMode";
import { navigate } from "@reach/router";
import { formatMonto } from "../../utils";
import { getLocationName } from "../../utils/location";
import { ModalContext } from "../../context/ModalContext";
import { ProductsContext } from "../../context/ProductsContext";
import { hasSalePrice } from "../../utils/products";

const ProductRow = ({ product, handleCallback }) => {
  const { name, available } = product;
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { deleteProduct, duplicateProduct } = useContext(ProductsContext);

  const handleDelete = () => {
    modalComponent(
      "Precaución",
      <form
        onSubmit={(e) => {
          e.preventDefault();
          deleteProduct(product.product_id);
        }}
      >
        <p>
          ¿Estás segura que deseas eliminar el producto {product.name}? Esta
          acción NO puede deshacerse.
        </p>
        <div className="row">
          <div className="col-6">
            <button
              type="button"
              onClick={clearModal}
              className="btn w-100 text-muted"
            >
              Cancelar
            </button>
          </div>
          <div className="col-6">
            <button type="submit" className="btn w-100 btn-danger">
              Eliminar
            </button>
          </div>
        </div>
      </form>
    );
  };

  const confirmDuplicate = () => {
    modalComponent(
      "Duplicar Producto",
      <div>
        <p>¿Estás segura que quieres duplicar el producto {product.name}?</p>
        <div className="row">
          <div className="col-6">
            <button onClick={clearModal} className="btn w-100 text-muted">
              Cancelar
            </button>
          </div>
          <div className="col-6">
            <button
              onClick={() => duplicateProduct(product.product_id, handleCallback)}
              className="btn w-100 btn-primary"
            >
              Duplicar
            </button>
          </div>
        </div>
      </div>
    );
  };

  const onSale = hasSalePrice(product);

  return (
    <tr>
      <td className="wide">{name}</td>
      <td>
        {available ? (
          <i className="fa fa-check-circle text-success"></i>
        ) : (
          <i className="fa fa-times-circle text-danger"></i>
        )}
      </td>
      <td>
        <ProductMode product={product} />
      </td>
      <td>
        {getLocationName(product.location)}
        {product.start_date !== null || product.end_date !== null ? (
          <p className="small mb-0 bold text-accent">
            {product.start_date !== null
              ? moment(product.start_date).utc().format("DD MMM YYYY")
              : ""}{" "}
            -{" "}
            {product.end_date !== null
              ? moment(product.end_date).utc().format("DD MMM YYYY")
              : ""}
          </p>
        ) : (
          ""
        )}
      </td>
      <td>
        {onSale ? (
          <p>
            <del className="text-danger">{"$"}{formatMonto(product.price)}</del>{" "}
            {"$"}{formatMonto(product.sale_price)}
          </p>
        ) : (
          <p>{"$"}{formatMonto(product.price)}</p>
        )}
      </td>
      <td>
        <button
          className="btn btn-outline-dark btn-sm"
          onClick={confirmDuplicate}
        >
          <i className="fa fa-copy"></i>
        </button>
        <button
          className="btn btn-outline-dark btn-sm ms-2"
          onClick={() => navigate(`/myadmin/products/${product.product_id}`)}
        >
          <i className="fa fa-edit"></i>
        </button>
        <button
          className="btn btn-outline-danger btn-sm mx-3"
          onClick={handleDelete}
        >
          <i className="fa fa-trash"></i>
        </button>
      </td>
    </tr>
  );
};

export default ProductRow;
