import React from "react";
import moment from "moment";

const DiscountRow = ({ descuento, editarDescuento, confirmDelete }) => {
  const { discount_id, title, code, amount, is_percent } = descuento;

  const renderDate = (key) => {
    if (descuento[key] && descuento[key] !== null) {
      return moment(descuento[key]).format("DD MMM YYYY");
    }
  };

  return (
    <tr className="align-items-center">
      <td>{code}</td>
      <td>
        {!is_percent && "$"}
        {amount}
        {is_percent && "%"}
      </td>
      <td>{renderDate("start_date")}</td>
      <td>{renderDate("expiration_date")}</td>
      <td>
        <button
          className="btn btn-outline-dark btn-sm"
          onClick={() => editarDescuento(discount_id)}
        >
          <i className="fa fa-edit me-1"></i> Editar
        </button>
        <button
          className="btn btn-outline-danger btn-sm mx-3"
          onClick={() =>
            confirmDelete({
              discount_id,
              title,
              code,
            })
          }
        >
          <i className="fa fa-trash me-1"></i> Eliminar
        </button>
      </td>
    </tr>
  );
};

export default DiscountRow;
