import {
  SET_PRODUCT,
  PRODUCTS_RECEIVED,
  SET_PROPERTY_PRODUCT,
  CREATE_PRODUCT,
} from "../types/products";

const schema = {
  product_id: "",
  description: "",
  short_description: "",
  payment_plans: [],
  course_products: [],
};

const ProductsReducer = (state, { type, payload }) => {
  switch (type) {
    case PRODUCTS_RECEIVED:
      return { ...state, products: payload };
    case SET_PRODUCT:
      return { ...state, product: payload };
    case CREATE_PRODUCT:
      return { ...state, product: schema };
    case SET_PROPERTY_PRODUCT:
      const { key, value } = payload;
      const product = { ...state.product };
      product[key] = value;
      return { ...state, product };
    default:
      return { ...state };
  }
};
export default ProductsReducer;
