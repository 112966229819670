import React, { useContext } from "react";
import { LessonsContext } from "../../context/LessonsContext";

const LessonDeleteConfirm = ({ lesson, handleCallback }) => {
  const { deleteLesson } = useContext(LessonsContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    deleteLesson(lesson.lesson_id, handleCallback);
  };

  return (
    <form onSubmit={handleSubmit}>
      <p>
        ¿Estás segura que deseas eliminar la lección {lesson.name}? Esta acción
        NO puede deshacerse.
      </p>
      <div className="row">
        <div className="col-6">
          <button className="btn w-100 text-muted" type="button">
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button className="btn w-100 btn-danger" type="submit">
            Eliminar
          </button>
        </div>
      </div>
    </form>
  );
};

export default LessonDeleteConfirm;
