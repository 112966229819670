import React from "react";
import { S3_ENDPOINT } from "../../utils";
import { Link } from "@reach/router";

const LessonCard = ({ lesson, handleEdit, handleDelete }) => {
  const renderImage = () => {
    let { media, file } = lesson;
    if (file && file !== null) {
      return (
        <img
          src={`${S3_ENDPOINT}/${file.name}.${file.type}`}
          className="mw-100 w-100 px-0"
        />
      );
    }
    if (media) {
      if (Array.isArray(media)) {
        media = media
          .filter((media) => media.file !== null)
          .find((media) =>
            ["jpg", "png", "jpeg", "gif"].includes(media.file.type)
          );
        if (!media) {
          media = null;
        }
      }
      if (media !== null && !Array.isArray(media)) {
        let { file } = media;
        return (
          <img
            src={`${S3_ENDPOINT}/${file.name}.${file.type}`}
            className="mw-100 w-100 px-0"
          />
        );
      }
    }
    return <h4>{lesson.name}</h4>;
  };

  const renderInstructor = () => {
    if (lesson.instructor && lesson.instructor !== null) {
      return lesson.instructor.name;
    }
  };

  return (
    <div className="lesson-media col-6 col-sm-6 col-xl-3 mb-3">
      <div className="border shadow-sm">
        <div className="lesson-media-content bg-white row mx-0 align-items-center">
          {renderImage()}
        </div>
        <div className="lesson-media-buttons row mx-0 bg-light align-items-center border py-2">
          <div className="col-12 col-md-6 text-start">{renderInstructor()}</div>
          <div className="col-12 col-md-6">
            <Link
              to={`/myadmin/lessons/${lesson.lesson_id}`}
              className="btn btn-sm btn-outline-dark me-1"
            >
              <i className="fa fa-eye"></i>
            </Link>
            <button
              className="btn btn-sm btn-outline-dark me-1"
              onClick={() => handleEdit(lesson)}
            >
              <i className="fa fa-edit"></i>
            </button>
            <button
              onClick={() => handleDelete(lesson)}
              className="btn btn-sm btn-outline-danger"
            >
              <i className="fa fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LessonCard;
