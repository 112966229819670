import React, { createContext, useContext, useReducer } from "react";
import InvoicesReducer from "../reducers/InvoicesReducer";
import InvoicesService from "../services/InvoicesService";
import {
  CLEAR_INVOICES,
  CREATE_INVOICE,
  HIDE_SPINNER,
  INVOICES_RECIBIDOS,
  SET_INVOICE,
  SET_PROPERTY_INVOICE,
  SHOW_SPINNER,
} from "../types";
import { hideModal } from "../utils";
import { CustomerContext } from "./CustomerContext";
import { ModalContext } from "./ModalContext";

const initialState = {
  invoices: null,
  spinner: false,
};

export const InvoicesContext = createContext(initialState);

export const InvoicesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(InvoicesReducer, initialState);

  const { success, alert } = useContext(ModalContext);

  const { getCustomer } = useContext(CustomerContext);

  const createInvoice = () => {
    dispatch({ type: CREATE_INVOICE });
  };

  const clearInvoice = () => {
    dispatch({ type: SET_INVOICE, payload: null });
  };

  const setInvoice = (invoice) => {
    dispatch({ type: SET_INVOICE, payload: invoice });
  };

  const getInvoices = (start_date, end_date, filters) => {
    dispatch({ type: SHOW_SPINNER });
    dispatch({ type: CLEAR_INVOICES });
    InvoicesService.getInvoicesAdmin(start_date, end_date, filters)
      .then((res) => {
        const { invoices } = res.data;
        dispatch({ type: HIDE_SPINNER });
        dispatch({ type: INVOICES_RECIBIDOS, payload: invoices });
      })
      .catch((error) => {
        alert(error);
        dispatch({ type: HIDE_SPINNER });
      });
  };

  const setPropertyInvoice = (key, value) => {
    dispatch({ type: SET_PROPERTY_INVOICE, payload: { key, value } });
  };

  const postInvoice = (invoice) => {
    let service = InvoicesService.putInvoice;
    if (isNaN(invoice.invoice_id)) {
      service = InvoicesService.postInvoice;
    }
    service(invoice).then(() => {
      getCustomer(invoice.user_id);
      success("Cargo guardado.");
      hideModal();
    });
  };

  return (
    <InvoicesContext.Provider
      value={{
        ...state,
        setInvoice,
        getInvoices,
        postInvoice,
        clearInvoice,
        createInvoice,
        setPropertyInvoice,
      }}
    >
      {children}
    </InvoicesContext.Provider>
  );
};
