import React, { useEffect, useContext } from "react";
import { CoachesContext } from "../../context/CoachesContext";
import BirthdateInput from "../common/BirthdateInput";
import ImageInput from "../common/ImageInput";

const CoachForm = ({ idCoach }) => {
  const { coach, getCoach, createCoach, postCoach, setPropiedadCoach } =
    useContext(CoachesContext);

  useEffect(() => {
    fetchCoach(idCoach);
  }, []);

  useEffect(() => {
    fetchCoach(idCoach);
  }, [idCoach]);

  const fetchCoach = (idCoach) => {
    if (isNaN(idCoach)) {
      createCoach();
    } else {
      getCoach(idCoach);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postCoach(coach);
  };

  const renderForm = () => {
    if (coach && coach !== null) {
      const { file, name, last_name, nick_name, birthdate, short_bio } = coach;
      return (
        <form onSubmit={handleSubmit}>
          <label>Nombre</label>
          <input
            type="text"
            className="form-control mb-3"
            value={name !== null ? name : ""}
            onChange={(e) => setPropiedadCoach("name", e.target.value)}
          />
          <label>Apellidos</label>
          <input
            type="text"
            className="form-control mb-3"
            value={last_name !== null ? last_name : ""}
            onChange={(e) => setPropiedadCoach("last_name", e.target.value)}
          />
          <label>Fotografía</label>
          <ImageInput key="picture" value={file} modifier={setPropiedadCoach} />
          <label>Fecha de Nacimiento</label>
          <BirthdateInput
            value={birthdate}
            modifier={(value) => setPropiedadCoach("birthdate", value)}
          />
          <label>Apodo</label>
          <input
            type="text"
            className="form-control mb-3"
            value={nick_name !== null ? nick_name : ""}
            onChange={(e) => setPropiedadCoach("nick_name", e.target.value)}
          />
          <label>Bio</label>
          <textarea
            rows="5"
            className="form-control mb-3"
            value={short_bio !== null ? short_bio : ""}
            onChange={(e) => setPropiedadCoach("short_bio", e.target.value)}
          />
          <button type="submit" className="btn btn-primary mt-2">
            Guardar
          </button>
        </form>
      );
    }
  };

  return <div className="container-fluid px-0">{renderForm()}</div>;
};

export default CoachForm;
