import React, { useContext } from "react";
import parse from "html-react-parser";
import { S3_ENDPOINT } from "../../utils";
import { CoursesContext } from "../../context/CoursesContext";
import { ModalContext } from "../../context/ModalContext";

const CourseData = ({ course }) => {
  const { modalComponent } = useContext(ModalContext);
  const { setPropertyCourse } = useContext(CoursesContext);
  const { file, name, description, short_description } = course;

  const getSrc = () => {
    let src = "tbm-cert-logo.png";
    if (file && file !== null) {
      src = `${file.name}.${file.type}`;
    }
    return `${S3_ENDPOINT}/${src}`;
  };

  return (
    <div className="container-fluid px-0">
      <div className="row mb-3 align-items-center mx-0">
        <div className="col-12 col-md-4 ps-0">
          <img
            src={getSrc()}
            className="w-100 br-10 profile-image"
            alt="Course Thumbnail"
          />
        </div>
        <div className="col-12 col-md-8">
          <div className="row align-items-center border-bottom pb-3 mb-3">
            <div className="col-10">
              <h3 className="mb-0">{name}</h3>
            </div>
            <div className="col-2">
              <button className="btn btn-sm btn-outline-dark">
                <i className="fa fa-edit"></i> Editar
              </button>
            </div>
          </div>
          <label className="bold">Descripción Corta</label>
          <div className="mb-2">
            {parse(short_description)}
            <button className="btn btn-sm d-block my-3 btn-outline-dark">
              <i className="fa fa-edit me-1"></i> Editar
            </button>
          </div>
          <label className="bold">Descripción</label>
          <div className="mb-2">
            {parse(description)}{" "}
            <button className="btn btn-sm d-block my-3 btn-outline-dark">
              <i className="fa fa-edit me-1"></i> Editar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseData;
