import React, { useContext, useEffect } from "react";
import CoursesTable from "../../components/courses/CoursesTable";
import PanelTitle from "../../components/global/PanelTitle";
import { CoursesContext } from "../../context/CoursesContext";

const AdminCourses = () => {
  const { courses, getCourses } = useContext(CoursesContext);

  useEffect(() => {
    getCourses();
  }, []);

  return (
    <div className="container-fluid">
      <PanelTitle title="Certificaciones" />
      <CoursesTable courses={courses} />
    </div>
  );
};

export default AdminCourses;
