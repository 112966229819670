import api from "./api";

const route = "/sections";

export default {
  getSection: (section_id) => api.get(`${route}/${section_id}`),
  getSectionsCourse: (course_id) => api.get(`${route}/course/${course_id}`),
  postSection: (section) => api.post(route, { ...section }),
  putSection: (section) => api.put(route, { ...section }),
  updateOrdenSections: (sections) => api.put(`${route}/orden`, { sections }),
  deleteSection: (section_id) => api.delete(`${route}/${section_id}`),
};
