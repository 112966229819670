import api from "./api";
import { getArgs } from "../utils";

const route = "/products";

const ProductsService = {
  getProducts: (filters) => api.get(`${route}?${getArgs(filters)}`),
  getAllProducts: (filters) =>
    api.get(`${route}/admin/all?${getArgs(filters)}`),
  getSingleProduct: (product_id) => api.get(`${route}/${product_id}`),
  postProduct: (product) => api.post(route, { ...product }),
  putProduct: (product) => api.put(route, { ...product }),
  deleteProduct: (product_id) => api.delete(`${route}/${product_id}`),
};
export default ProductsService;
