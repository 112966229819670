import React, { useContext, useEffect } from "react";
import LessonMediaForm from "../../components/lessons/LessonMediaForm";
import PanelTitle from "../../components/global/PanelTitle";
import LessonData from "../../components/lessons/LessonData";
import LessonForm from "../../components/lessons/LessonForm";
import SingleMedia from "../../components/media/SingleMedia";
import { LessonsContext } from "../../context/LessonsContext";
import { ModalContext } from "../../context/ModalContext";
import { hideModal } from "../../utils";

const AdminSingleLesson = ({ lesson_id }) => {
  const {
    lesson,
    postLesson,
    createMedia,
    setLessonMedia,
    getSingleLesson,
    postLessonMedia,
    clearLessonMedia,
    deleteLessonMedia,
  } = useContext(LessonsContext);

  const { modalComponent, clearModal } = useContext(ModalContext);

  useEffect(() => {
    loadLesson();
  }, []);

  const loadLesson = () => {
    getSingleLesson(lesson_id);
  };

  const clearModalData = () => {
    hideModal();
    clearModal();
  };

  const deleteCallback = () => {
    clearModalData();
    loadLesson();
  };

  const handleEditLeccion = () => {
    modalComponent(
      "Editar Lección",
      <LessonForm handleSubmit={postLesson} handleCancel={clearModalData} />
    );
  };

  const handleCancel = () => {
    clearModalData();
    clearLessonMedia();
  };

  const handleSubmitLessonMedia = (media) => {
    media.lesson_id = lesson_id;
    postLessonMedia(media);
  };

  const handleCreateMedia = () => {
    createMedia();
    modalComponent(
      "Agregar Medios",
      <LessonMediaForm
        handleCancel={handleCancel}
        handleSubmit={handleSubmitLessonMedia}
      />
    );
  };

  const handleEditMedia = (media) => {
    setLessonMedia(media);
    modalComponent(
      "Editar Medios",
      <LessonMediaForm
        handleCancel={handleCancel}
        handleSubmit={handleSubmitLessonMedia}
      />
    );
  };

  const confirmDeleteMedia = (media) => {
    modalComponent(
      "¿Eliminar medios?",
      <div className="container-fluid px-0">
        <p>
          ¿Quieres eliminar el medio {media.name}? Se perderá el acceso al
          elemento inmediatamente y esta acción NO puede deshacerse.
        </p>
        <button
          className="btn btn-danger"
          onClick={() =>
            deleteLessonMedia(media.lesson_media_id, deleteCallback)
          }
        >
          <i className="fa fa-trash"></i> Eliminar
        </button>
      </div>
    );
  };

  const renderLessonForm = () => {
    if (lesson && lesson !== null) {
      return <LessonData lesson={lesson} handleEdit={handleEditLeccion} />;
    }
  };

  const renderLessonMedia = () => {
    if (lesson && lesson !== null) {
      if (Array.isArray(lesson.media)) {
        if (lesson.media.length > 0) {
          return lesson.media.map((media) => (
            <SingleMedia
              media={media}
              key={media.lesson_media_id}
              handleEdit={handleEditMedia}
              handleDelete={confirmDeleteMedia}
            />
          ));
        }
      }
      return <p>No hay contenido en esta lección.</p>;
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="container-fluid">
      <PanelTitle title="Editar Lección" hideButton />
      <div className="card mb-3">{renderLessonForm()}</div>
      <div className="container-fluid">
        <PanelTitle title="Medios" onClick={handleCreateMedia} />
      </div>
      <div className="row mx-0">{renderLessonMedia()}</div>
    </div>
  );
};

export default AdminSingleLesson;
