export const getCourseFinalExam = (course) => {
  if (course && course !== null) {
    const { tests } = course;
    if (Array.isArray(tests)) {
      return tests[0];
    }
  }
};

export const getUserFinalExamAttempt = (course_access) => {
  if (
    course_access.user &&
    course_access.user !== null &&
    course_access.course &&
    course_access.course !== null
  ) {
    const { attempts } = course_access.user;
    const { course } = course_access;
    if (course && course !== null) {
      const final_exam = getCourseFinalExam(course);
      if (final_exam && final_exam !== null) {
        const attempt = attempts.find(
          ({ test_id }) => test_id === final_exam.test_id
        );
        return attempt;
      }
    }
  }
};
