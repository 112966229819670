import api from "./api";

const route = "/courses";

export default {
  getCourses: () => api.get(`${route}/admin/all`),
  getSingleCourse: (course_id) => api.get(`${route}/${course_id}`),
  completeCourse: (course_id, user_id) =>
    api.post(`${route}/complete`, { course_id, user_id }),
  uncompleteCourse: (course_id, user_id) =>
    api.post(`${route}/uncomplete`, { course_id, user_id }),
  postCoursePrerequisite: (course_id, prerequisite_course_id) =>
    api.post(`${route}/prerequisite`, { course_id, prerequisite_course_id }),
  deleteCoursePrerequisite: (course_id, prerequisite_course_id) =>
    api.delete(`${route}/${course_id}/prerequisite/${prerequisite_course_id}`),
};
