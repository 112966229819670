import React, { useState, useEffect, useContext } from "react";
import { AttemptsContext } from "../../context/AttemptsContext";
import UserAnswerFeedbackForm from "./UserAnswerFeedbackForm";
import UserAnswerFeedbackData from "./UserAnswerFeedbackData";
import { S3_ENDPOINT, downloadFile } from "../../utils";

const AttemptQuestion = ({ canEdit, question, answers, handleCallback }) => {
  const [questionAnswer, setQuestionAnswer] = useState(null);
  const { user_answer } = useContext(AttemptsContext);

  useEffect(() => {
    const currentAnswer = findQuestionAnswer(question);
    setQuestionAnswer(currentAnswer);
  }, [question, answers]);

  const findQuestionAnswer = (question) => {
    if (Array.isArray(answers)) {
      return answers.find(
        (answer) =>
          parseInt(answer.question_id) === parseInt(question.question_id)
      );
    }
  };

  const renderIcon = (questionAnswer) => {
    if (questionAnswer.score !== null) {
      if (questionAnswer.score > 0) {
        return <i className="fa fa-check text-success me-2"></i>;
      }
      return <i className="fa fa-times text-danger me-2"></i>;
    }
  };

  const renderContent = (questionAnswer) => {
    let source = String(questionAnswer.content);
    if (source.includes("http")) {
      if (source.includes("you")) {
        let video_source = source;
        if (source.includes("youtu.be")) {
          video_source =
            "https://www.youtube.com/embed/" + source.split("be/")[1];
        } else {
          video_source =
            "https://www.youtube.com/embed/" + source.split("v=")[1];
        }
        return (
          <div className="row">
            <div className="col-12 col-md-6">
              <iframe
                src={video_source}
                title="YouTube video player"
                className="mw-100 w-100 shadow mb-3"
                height="500px"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
              <a
                href={source}
                target="_blank"
                className="btn btn-outline-accent"
              >
                <i className="fa fa-eye"></i> Ver en YouTube
              </a>
            </div>
            <div className="col-12 col-md-6">
              {renderFeedback(questionAnswer)}
            </div>
          </div>
        );
      }
      if (source.includes("drive")) {
        let video_source = source;
        if (source.includes("view")) {
          video_source = video_source.replace("view", "preview");
        }
        return (
          <div className="row">
            <div className="col-12 col-md-6">
              <iframe
                src={video_source}
                className="mw-100 w-100 mb-3"
                height="auto"
                width="auto"
                style={{ minHeight: 300 }}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
              <a
                href={source}
                target="_blank"
                className="btn btn-outline-accent"
              >
                <i className="fa fa-eye"></i> Ver en Drive
              </a>
            </div>
            <div className="col-12 col-md-6">
              {renderFeedback(questionAnswer)}
            </div>
          </div>
        );
      }
      return (
        <div className="row">
          <div className="col-12 col-md-6">
            <video
              className="w-100 d-block m-auto"
              style={{ maxWidth: 500 }}
              playsInline
              controls
            >
              <source src={questionAnswer.content} type="video/mp4" />
            </video>
            <div className="my-3">
              <button
                onClick={() =>
                  downloadFile(
                    questionAnswer.content,
                    String(questionAnswer.content).replace(
                      `${S3_ENDPOINT}/`,
                      ""
                    )
                  )
                }
                className="btn btn-outline-accent shadow-sm me-2"
              >
                <i className="fa fa-download me-2"></i>
                Descargar Video
              </button>
              <a
                href={source}
                target="_blank"
                className="btn btn-outline-accent me-2 shadow-sm"
              >
                <i className="fas fa-external-link-alt me-2"></i>
                Abrir Enlace
              </a>
            </div>
          </div>
          <div className="col-12 col-md-6">
            {renderFeedback(questionAnswer)}
          </div>
        </div>
      );
    }
    return (
      <p className="px-3">
        {renderIcon(questionAnswer)} {questionAnswer.content}
      </p>
    );
  };

  const renderFeedback = (questionAnswer) => {
    if (user_answer !== null) {
      if (user_answer.user_answer_id === questionAnswer.user_answer_id) {
        return (
          <UserAnswerFeedbackForm
            user_answer={user_answer}
            handleCallback={handleCallback}
          />
        );
      }
    }
    return (
      <UserAnswerFeedbackData
        canEdit={canEdit}
        questionAnswer={questionAnswer}
      />
    );
  };

  const renderAnswer = () => {
    if (questionAnswer && questionAnswer !== null) {
      return <div className="my-3">{renderContent(questionAnswer)}</div>;
    }
  };

  return (
    <div className="w-100 mb-4">
      <p className="p-3 bold mb-1 bg-light">
        #{question.order} - {question.content}
      </p>
      {renderAnswer()}
    </div>
  );
};

export default AttemptQuestion;
