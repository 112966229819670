import React, { useContext } from "react";
import { PurchasesContext } from "../../context/PurchasesContext";
import { ModalContext } from "../../context/ModalContext";
import { UserContext } from "../../context/UserContext";
import EditPurchaseForm from "./EditPurchaseForm";
import StatusBadge from "../common/StatusBadge";
import { formatMonto } from "../../utils";
import { Link } from "@reach/router";
import moment from "moment";
import { getProductLocation } from "../../utils/products";

const PurchaseRow = ({ purchase, showType }) => {
  const { user } = useContext(UserContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { setPurchase, deletePurchase } = useContext(PurchasesContext);

  const handleEdit = () => {
    setPurchase(purchase);
    modalComponent(
      "Editar Compra",
      <EditPurchaseForm handleCancel={clearModal} />
    );
  };

  const handleDelete = () => {
    modalComponent(
      "Eliminar Compra",
      <div>
        <p>
          ¿Estás segura que deseas eliminar la compra #{purchase.purchase_id}
        </p>
        <p>
          Esta acción NO puede deshacerse y la alumna perderá acceso a los
          cursos relacionados a esta compra.
        </p>
        <div className="row">
          <div className="col-6">
            <button onClick={clearModal} className="btn w-100 text-muted">
              Cancelar
            </button>
          </div>
          <div className="col-6">
            <button
              type="submit"
              className="btn w-100 btn-primary"
              onClick={() => deletePurchase(purchase.purchase_id)}
            >
              <i className="fa fa-trash me-2"></i> Eliminar
            </button>
          </div>
        </div>
      </div>
    );
  };

  const canEdit = () => {
    return (
      purchase.status !== "revoked" && ["admin", "manager"].includes(user.role)
    );
  };

  const renderActions = () => {
    if (canEdit()) {
      return (
        <div>
          <button
            className="btn btn-sm btn-outline-dark me-2"
            onClick={handleEdit}
          >
            <i className="fa fa-edit"></i>
          </button>
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={handleDelete}
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      );
    }
  };

  const renderCustomer = () => {
    if (purchase.user && purchase.user !== null) {
      return (
        <td>
          <Link to={`/myadmin/customer/${purchase.user.user_id}`}>
            <i className="fa fa-eye"></i> {purchase.user.name}{" "}
            {purchase.user.last_name}
          </Link>
        </td>
      );
    }
  };

  const isFirstPurchase = () => {
    if (purchase.user && purchase.user !== null) {
      if (Array.isArray(purchase.user.invoices))
        return purchase.user.invoices.length === 1;
    }
  };

  const renderDiscountCode = () => {
    if (purchase.discount && purchase.discount !== null) {
      return purchase.discount.code;
    }
  };

  const renderBadge = (product) => {
    return (
      <span
        className={`badge badge-pill text-uppercase text-dark me-2 ${
          product.mode === "on-demand" ? "bg-primary" : "bg-accent"
        }`}
      >
        {product.mode === "live" ? "En Vivo" : "On Demand"}
      </span>
    );
  };

  const renderProduct = () => {
    if (purchase.product && purchase.product !== null) {
      return (
        <span>
          {purchase.product.name} - {renderBadge(purchase.product)} -{" "}
          {getProductLocation(purchase.product)}{" "}
        </span>
      );
    }
  };

  const renderPaymentMethod = () => {
    let { payment_method_id, order_id } = purchase;
    switch (payment_method_id) {
      case 1:
        return (
          <a
            href={`https://dashboard.stripe.com/payment_intents/${order_id}`}
            target="_blank"
          >
            Stripe
          </a>
        );
      case 2:
        return <span>Transferencia/Depósito</span>;
      case 3:
        return (
          <a
            href={`https://www.paypal.com/activity/payment/${order_id}`}
            target="_blank"
          >
            PayPal
          </a>
        );
    }
  };

  const renderType = () => {
    if (showType) {
      return (
        <td>
          {purchase.invoice_id && purchase.invoice_id !== null
            ? "Cargo"
            : "Compra"}
        </td>
      );
    }
  };

  return (
    <tr className="small border-bottom align-middle hover-light">
      <td>
        {purchase.invoice_id ? purchase.invoice_id : purchase.purchase_id}{" "}
        {purchase.admin_enabled && <i className="fa fa fa-user-shield"></i>}{" "}
        {isFirstPurchase() && <i className="fa fa-star text-warning"></i>}
      </td>
      {renderType()}
      {renderCustomer()}
      <td>{renderProduct()}</td>
      <td>{moment(purchase.createdAt).format("DD MMM YYYY HH:mm")}</td>
      <td>
        {purchase.is_gift && <i className="fas fa-gift me-1"></i>}
        {"$"}
        {formatMonto(purchase.amount)} MXN
      </td>
      <td>{renderPaymentMethod()}</td>
      <td>
        <StatusBadge
          order_id={purchase.order_id}
          status={purchase.status}
          date={purchase.updatedAt}
        />
      </td>
      <td>{renderDiscountCode()}</td>
      <td>{renderActions()}</td>
    </tr>
  );
};

export default PurchaseRow;
