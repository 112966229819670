import React, { useReducer, createContext, useContext } from "react";
import ProductsReducer from "../reducers/ProductsReducer";
import {
  SET_PROPERTY_PRODUCT,
  PRODUCTS_RECEIVED,
  SET_PRODUCT,
  CREATE_PRODUCT,
} from "../types/products";
import ProductsService from "../services/ProductsService";
import { ModalContext } from "./ModalContext";
import AdjuntosService from "../services/AdjuntosService";

const initialState = {
  products: null,
  product: null,
};

export const ProductsContext = createContext(initialState);

export const ProductsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ProductsReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getProducts = (filters) => {
    ProductsService.getProducts(filters).then((res) => {
      const { products } = res.data;
      dispatch({ type: PRODUCTS_RECEIVED, payload: products });
    });
  };

  const getSingleProduct = (product_id) => {
    ProductsService.getSingleProduct(product_id).then((res) => {
      const { product } = res.data;
      dispatch({ type: SET_PRODUCT, payload: product });
    });
  };

  const getAllProducts = (filters) => {
    ProductsService.getAllProducts(filters).then((res) => {
      const { products } = res.data;
      dispatch({ type: PRODUCTS_RECEIVED, payload: products });
    });
  };

  const setPropertyProduct = (key, value) => {
    dispatch({ type: SET_PROPERTY_PRODUCT, payload: { key, value } });
  };

  const setProduct = (product) => {
    dispatch({ type: SET_PRODUCT, payload: product });
  };

  const clearProduct = () => {
    dispatch({ type: SET_PRODUCT, payload: null });
  };

  const clearProducts = () => {
    dispatch({ type: PRODUCTS_RECEIVED, payload: null });
  };

  const createProduct = () => {
    dispatch({ type: CREATE_PRODUCT });
  };

  const uploadFile = (file) => {
    const formData = new FormData();
    formData.append("file", file);

    return AdjuntosService.postAdjunto(formData);
  }

  const saveProduct = (product, callback) => {
    let service = ProductsService.putProduct;
    if (isNaN(parseInt(product.product_id))) {
      service = ProductsService.postProduct;
    }

    if(product.new_file) {
      uploadFile(product.new_file).then((res) => {
        const { file_id } = res.data;
        product.thumbnail = file_id;

        delete product.new_file;
        delete product.new_file_src;

        service(product)
          .then(() => {
            if (typeof callback === "function") {
              callback();
            }
            success("Producto guardado.");
            clearModal();
          })
          .catch(alert);
      })
      .catch(alert);

    } else {
      service(product)
      .then(() => {
        if (typeof callback === "function") {
          callback();
        }
        success("Producto guardado.");
        clearModal();
      })
      .catch(alert);
    }
  };

  const deleteProduct = (product_id) => {
    ProductsService.deleteProduct(product_id).then(() => {
      success("Producto eliminado.");
      getAllProducts();
      clearModal();
    });
  };

  const duplicateProduct = (product_id, callback) => {
    ProductsService.getSingleProduct(product_id).then((res) => {
      const { product } = res.data;
      saveProduct({ ...product, product_id: null, name: `Copia de ${product.name}` }, callback);
    });
  }

  return (
    <ProductsContext.Provider
      value={{
        ...state,
        setProduct,
        saveProduct,
        getProducts,
        clearProduct,
        deleteProduct,
        createProduct,
        clearProducts,
        getAllProducts,
        duplicateProduct,
        getSingleProduct,
        setPropertyProduct,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};
