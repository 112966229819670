import {
  ANSWERS_RECEIVED,
  SET_ANSWER,
  SET_PROPERTY_USER_ANSWER,
  SET_USER_ANSWER,
} from "../types/answers";
import {
  ATTEMPTS_RECEIVED,
  SET_PROPERTY_ATTEMPT,
  SET_SINGLE_ATTEMPT,
  SET_UPDATE,
} from "../types/attempts";
import { QUESTIONS_RECEIVED, SET_QUESTION } from "../types/questions";
import { SINGLE_TEST_RECEIVED } from "../types/tests";

const AttemptsReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_PROPERTY_ATTEMPT: {
      const { key, value } = payload;
      const attempt = { ...state.attempt };
      attempt[key] = value;
      return { ...state, attempt };
    }
    case SET_USER_ANSWER:
      return { ...state, user_answer: payload };
    case SET_PROPERTY_USER_ANSWER:
      const user_answer = { ...state.user_answer };
      const { key, value } = payload;
      user_answer[key] = value;
      return { ...state, user_answer };
    case ATTEMPTS_RECEIVED:
      return { ...state, attempts: payload };
    case SET_UPDATE:
      return { ...state, update: payload };
    case SET_ANSWER: {
      let answers = [...state.answers];
      const index = answers.findIndex(
        (answer) => answer.question_id === payload.question_id
      );
      if (index === -1) answers.push(payload);
      else answers[index] = payload;
      return { ...state, answers: [...answers] };
    }
    case SET_SINGLE_ATTEMPT:
      return { ...state, attempt: payload };
    case QUESTIONS_RECEIVED:
      return { ...state, questions: payload };
    case ANSWERS_RECEIVED:
      return { ...state, answers: payload };
    case SINGLE_TEST_RECEIVED:
      return { ...state, test: payload };
    case SET_QUESTION:
      return { ...state, question: payload };
    case SET_ANSWER:
      const { question_id, answer } = payload;
      const answers = [...state.answers];
      let index = answers.findIndex(
        (answer) => answer.question_id === question_id
      );
      if (index === -1) {
        answers.push({ question_id, answer });
      } else {
        answers[index] = { question_id, answer };
      }
      return { ...state, answers };
    default:
      return { ...state };
  }
};
export default AttemptsReducer;
