import React from "react";

const ToggleStatus = ({ options, selected, modifier }) => {
  const renderOptions = () => {
    if (Array.isArray(options)) {
      return options.map(({ name, value }) => (
        <button
          key={value}
          className={`btn btn-sm btn-${
            selected === value ? "primary" : "light"
          } border br-0`}
          onClick={() => modifier(value)}
        >
          {name}
        </button>
      ));
    }
  };

  return <div className="button-group">{renderOptions()}</div>;
};

export default ToggleStatus;
