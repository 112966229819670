import api from "./api";

const route = "/lesson_media";

export default {
  postLessonMedia: (media) => api.post(route, { ...media }),
  putLessonMedia: (media) => api.put(route, { ...media }),
  deleteLessonMedia: (lesson_media_id) =>
    api.delete(`${route}/${lesson_media_id}`),
};
