import React from "react";
import { S3_ENDPOINT } from "../../utils";

const LessonData = ({ lesson, handleEdit }) => {
  const { file, name, description } = lesson;

  const getSrc = () => {
    let src = "tbm-cert-logo.png";
    if (file && file !== null) {
      src = `${file.name}.${file.type}`;
    }
    return `${S3_ENDPOINT}/${src}`;
  };

  return (
    <div className="container-fluid lesson-data px-0">
      <h4 className="pb-3 border-bottom">{name}</h4>
      <div className="mb-2">{description}</div>
      <button onClick={handleEdit} className="btn btn-primary mt-3">
        <i className="fa fa-edit me-3"></i>Editar Información
      </button>
    </div>
  );
};

export default LessonData;
