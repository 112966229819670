import React from "react";

const FinishTestForm = ({ handleCancel, handleSubmit }) => {
  return (
    <div>
      <p>
        ¿Deseas marcar este examen como terminado? Ya no podrás modificar el
        feedback que agregaste.
      </p>
      <div className="row">
        <div className="col-6">
          <button onClick={handleCancel} className="btn text-muted w-100">
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button onClick={handleSubmit} className="btn btn-primary w-100">
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};
export default FinishTestForm;
