import React, { useEffect, useContext } from "react";
import HeaderRow from "../../components/global/HeaderRow";
import LocationForm from "../../components/locations/LocationForm";
import { LocationsContext } from "../../context/LocationsContext";
import { ModalContext } from "../../context/ModalContext";
import { getLocationName } from "../../utils/location";
import { AddressContext } from "../../context/AddressContext";
import PanelTitle from "../../components/global/PanelTitle";

const AdminLocations = () => {
  const {
    locations,
    setLocation,
    getLocations,
    postLocation,
    createLocation,
    deleteLocation,
    setPropiedadLocation,
  } = useContext(LocationsContext);

  const { setAddress } = useContext(AddressContext);

  const { clearModal, modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getLocations();
  }, []);

  const editLocation = (location) => {
    setLocation(location);
    setAddress(location.address);
    modalComponent(
      "Editar Ubicación",
      <LocationForm
        modifier={setPropiedadLocation}
        postLocation={postLocation}
        handleCancel={clearModal}
      />
    );
  };

  const addLocation = () => {
    createLocation();
    setAddress({});
    modalComponent(
      "Agregar Ubicación",
      <LocationForm
        modifier={setPropiedadLocation}
        postLocation={postLocation}
        handleCancel={clearModal}
      />
    );
  };

  const confirmDelete = (location) => {
    modalComponent(
      "Cuidado",
      <div className="container-fluid px-0">
        <p>
          ¿Estás segura que deseas eliminar la ubicación {location.description}?
          Esta acción NO puede deshacerse.
        </p>
        <button
          className="btn btn-danger"
          onClick={() => deleteLocation(location.location_id)}
        >
          Eliminar
        </button>
      </div>
    );
  };

  const renderUbicaciones = () => {
    if (locations && locations !== null) {
      return locations.map((location) => (
        <div key={location.location_id} className="card p-3 no-scale small">
          <div className="row align-items-center">
            <div className="col col-md-4">{location.name}</div>
            <div className="col col-md-4">{getLocationName(location)}</div>
            <div className="col col-md-4">
              <button
                className="btn btn-sm btn-outline-dark"
                onClick={() => editLocation(location)}
              >
                <i className="fa fa-edit"></i>
              </button>
              <button
                className="btn btn-sm btn-outline-danger mx-3"
                onClick={() => confirmDelete(location)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
      ));
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="container-fluid">
      <PanelTitle title="Ubicaciones" onClick={addLocation} />
      <HeaderRow headers={["Nombre", "Dirección", "Acciones"]} />
      {renderUbicaciones()}
    </div>
  );
};

export default AdminLocations;
