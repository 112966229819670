import React, { useContext } from "react";
import { AttemptsContext } from "../../context/AttemptsContext";

const UserAnswerFeedbackForm = ({ user_answer, handleCallback }) => {
  const { setPropertyUserAnswer, setUserAnswer, saveUserAnswer } =
    useContext(AttemptsContext);
  return (
    <div className="container-fluid">
      <h3>Técnica</h3>
      <label>Calificación (0 al 100)</label>
      <input
        step=".01"
        type="number"
        value={user_answer.score_tecnica}
        className="form-control mb-3"
        onChange={(e) => setPropertyUserAnswer("score_tecnica", e.target.value)}
      />
      <label>Feedback</label>
      <textarea
        className="form-control mb-3"
        value={user_answer.feedback_tecnica}
        onChange={(e) =>
          setPropertyUserAnswer("feedback_tecnica", e.target.value)
        }
      />
      <h3>Coordinación</h3>
      <label>Calificación (0 al 100)</label>
      <input
        step=".01"
        type="number"
        value={user_answer.score_coordinacion}
        className="form-control mb-3"
        onChange={(e) =>
          setPropertyUserAnswer("score_coordinacion", e.target.value)
        }
      />
      <label>Feedback</label>
      <textarea
        className="form-control mb-3"
        value={user_answer.feedback_coordinacion}
        onChange={(e) =>
          setPropertyUserAnswer("feedback_coordinacion", e.target.value)
        }
      />
      <h3>Estructura de Rutinas</h3>
      <label>Calificación (0 al 100)</label>
      <input
        step=".01"
        type="number"
        value={user_answer.score_estructura}
        className="form-control mb-3"
        onChange={(e) =>
          setPropertyUserAnswer("score_estructura", e.target.value)
        }
      />
      <label>Feedback</label>
      <textarea
        className="form-control mb-3"
        value={user_answer.feedback_estructura}
        onChange={(e) =>
          setPropertyUserAnswer("feedback_estructura", e.target.value)
        }
      />
      <h3>Creatividad</h3>
      <label>Calificación (0 al 100)</label>
      <input
        step=".01"
        type="number"
        value={user_answer.score_creatividad}
        className="form-control mb-3"
        onChange={(e) =>
          setPropertyUserAnswer("score_creatividad", e.target.value)
        }
      />
      <label>Feedback</label>
      <textarea
        className="form-control mb-3"
        value={user_answer.feedback_creatividad}
        onChange={(e) =>
          setPropertyUserAnswer("feedback_creatividad", e.target.value)
        }
      />
      <h3>Cueing</h3>
      <label>Calificación (0 al 100)</label>
      <input
        step=".01"
        type="number"
        value={user_answer.score_cueing}
        className="form-control mb-3"
        onChange={(e) => setPropertyUserAnswer("score_cueing", e.target.value)}
      />
      <label>Feedback</label>
      <textarea
        className="form-control mb-3"
        value={user_answer.feedback_cueing}
        onChange={(e) =>
          setPropertyUserAnswer("feedback_cueing", e.target.value)
        }
      />
      <div className="row">
        <div className="col-6">
          <button
            onClick={() => setUserAnswer(null)}
            className="btn text-muted w-100"
          >
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button
            onClick={() => saveUserAnswer(user_answer, handleCallback)}
            className="btn btn-primary w-100"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserAnswerFeedbackForm;
