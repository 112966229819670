import React, { useEffect, useState } from "react";

const SearchableMultipleSelect = ({
  items,
  label,
  key_id,
  addItem,
  itemName,
  selected,
  fetchItems,
  removeItem,
  hideCreate,
  CustomComponent,
}) => {
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (query !== "") {
      fetchItems({ query });
    }
  }, [query]);

  const renderItems = () => {
    if (Array.isArray(items) && query !== "") {
      let itemsRender = [...items];
      if (query !== "") {
        itemsRender = itemsRender.filter(
          (item) =>
            String(item.name)
              .toLowerCase()
              .startsWith(String(query).toLowerCase()) ||
            String(item.last_name)
              .toLowerCase()
              .startsWith(String(query).toLowerCase())
        );
      }
      if (Array.isArray(selected)) {
        console.log(selected);
        console.log(itemsRender);
        const current = selected.map((item) => item[key_id]);
        itemsRender = itemsRender.filter(
          (item) => !current.includes(item[key_id])
        );
      }
      itemsRender = itemsRender.slice(0, 3).map((item) => (
        <li
          key={item[key_id]}
          className="dropdown-item border pointer"
          onClick={() => addItem(item)}
        >
          {CustomComponent && CustomComponent !== null ? (
            <CustomComponent item={item} />
          ) : (
            <span>{item.name}</span>
          )}
        </li>
      ));
      if (!hideCreate) {
        itemsRender.concat(
          <li
            key="nuevo"
            className="dropdown-item border pointer"
            onClick={() => {
              addItem({ [key_id]: "nuevo", name: query });
              setQuery("");
            }}
          >
            + Crear {itemName} "{query}"
          </li>
        );
      }
      return itemsRender;
    }
  };

  const renderSelected = () => {
    if (Array.isArray(selected)) {
      return selected.map((item) => (
        <span
          key={item[key_id]}
          className="badge badge-pill bg-light border text-dark p-2 me-1"
        >
          {item.name} {item.last_name}{" "}
          <i
            className="fa fa-times small ms-1 pointer"
            onClick={() => removeItem(item)}
          ></i>
        </span>
      ));
    }
  };

  return (
    <div className="mb-3">
      <label>{label}</label>
      <input
        type="text"
        value={query}
        className="form-control"
        placeholder="Buscar por nombre"
        onChange={(e) => setQuery(e.target.value)}
      />
      <div>{renderItems()}</div>
      <div className="py-2">{renderSelected()}</div>
    </div>
  );
};

export default SearchableMultipleSelect;
