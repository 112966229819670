import React, { useContext, useEffect } from "react";
import PanelTitle from "../global/PanelTitle";
import { AttemptsContext } from "../../context/AttemptsContext";
import HeaderRow from "../global/HeaderRow";
import { Link } from "@reach/router";

const CourseTestsAttemps = ({ customer_id }) => {
  const { attempts, getUserAttempts } = useContext(AttemptsContext);

  useEffect(() => {
    getUserAttempts(customer_id);
  }, []);

  const renderAttempts = () => {
    if (Array.isArray(attempts)) {
      let quizesRender = attempts.filter(
        (attempt) => attempt.test.course && attempt.test.course !== null
      );
      if (quizesRender.length === 0) {
        return (
          <p className="p-2 mb-0">
            Este usuario no ha subido ningún examen práctico.
          </p>
        );
      }
      return quizesRender.map((attempt) => (
        <div key={attempt.attempt_id} className="card py-2 px-3">
          <div className="row">
            <div className="col-4">
              <Link
                to={`/myadmin/customer/${customer_id}/attempts/${attempt.attempt_id}`}
              >
                {attempt.test.name}
              </Link>
            </div>
            <div className="col-4">{attempt.test.course.name}</div>
            <div className="col-4">{attempt.score}</div>
          </div>
        </div>
      ));
    }
  };

  return (
    <div className="container-fluid px-0 pb-3">
      <PanelTitle title="Exámenes Prácticos" hideButton />
      <HeaderRow headers={["Examen", "Certificación", "Resultado"]} />
      {renderAttempts()}
    </div>
  );
};

export default CourseTestsAttemps;
