import React, { createContext, useContext, useReducer } from "react";
import CourseAccessReducer from "../reducers/CourseAccessReducer";
import CourseAccessesService from "../services/CourseAccessesService";
import { SET_course_access } from "../types";
import { CustomerContext } from "./CustomerContext";
import { ModalContext } from "./ModalContext";
import {
  CREATE_COURSE_ACCESS,
  SET_PROPERTY_COURSE_ACCESS,
} from "../types/course_access";

const initialState = {
  course_accesses: null,
  course_access: null,
};

export const CourseAccessContext = createContext(initialState);

export const CourseAccessProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CourseAccessReducer, initialState);

  const { success, alert, clearModal } = useContext(ModalContext);

  const { getCustomer } = useContext(CustomerContext);

  const createCourseAccess = () => {
    dispatch({ type: CREATE_COURSE_ACCESS });
  };

  const setCourseAccess = (course_access) => {
    dispatch({ type: SET_course_access, payload: course_access });
  };

  const setPropertyCourseAccess = (key, value) => {
    dispatch({ type: SET_PROPERTY_COURSE_ACCESS, payload: { key, value } });
  };

  const saveCourseAccess = (course_access, callback) => {
    let service = CourseAccessesService.putCourseAccess;
    if (isNaN(parseInt(course_access.course_access_id))) {
      service = CourseAccessesService.postCourseAccess;
    }
    service(course_access)
      .then(() => {
        getCustomer(course_access.user_id);
        success("Acceso guardado.");
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch(alert);
  };

  const deleteCourseAccess = (course_access_id, callback) => {
    CourseAccessesService.deleteCourseAccess(course_access_id).then(() => {
      success("Acceso eliminado.");
      clearModal();
      if (typeof callback === "function") {
        callback();
      }
    });
  };

  return (
    <CourseAccessContext.Provider
      value={{
        ...state,
        setCourseAccess,
        saveCourseAccess,
        createCourseAccess,
        deleteCourseAccess,
        setPropertyCourseAccess,
      }}
    >
      {children}
    </CourseAccessContext.Provider>
  );
};
