import React, { createContext, useContext, useReducer } from "react";
import AddressReducer from "../reducers/AddressReducer";
import AddressService from "../services/AddressService";
import {
  SET_ADDRESS,
  ADDRESSES_RECEIVED,
  SET_PROPERTY_ADDRESS,
} from "../types/address";
import { ModalContext } from "./ModalContext";

const initialState = {
  addresses: null,
  address: null,
};
export const AddressContext = createContext(initialState);

export const AddressProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AddressReducer, initialState);

  const { success } = useContext(ModalContext);

  const getAddresses = () => {
    AddressService.getAddresses().then((res) => {
      const { addresses } = res.data;
      dispatch({ type: ADDRESSES_RECEIVED, payload: addresses });
    });
  };

  const setAddress = (address) => {
    dispatch({ type: SET_ADDRESS, payload: address });
  };

  const setPropertyAddress = (key, value) => {
    dispatch({ type: SET_PROPERTY_ADDRESS, payload: { key, value } });
  };

  const postAddress = (address, callback) => {
    let service = AddressService.putAddress;
    if (isNaN(parseInt(address.address_id))) {
      service = AddressService.postAddress;
    }
    service(address).then((res) => {
      success("Dirección guardada.");
      if (typeof callback === "function") {
        callback(res.data.address);
      }
    });
  };

  return (
    <AddressContext.Provider
      value={{
        ...state,
        setAddress,
        postAddress,
        getAddresses,
        setPropertyAddress,
      }}
    >
      {children}
    </AddressContext.Provider>
  );
};
