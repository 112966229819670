import {
  APPEND_PRODUCT_DISCOUNT,
  CREATE_DESCUENTO,
  DESCUENTOS_RECIBIDOS,
  REMOVE_PRODUCT_DISCOUNT,
  SET_DESCUENTO,
  SET_PROPIEDAD_DESCUENTO,
} from "../types/discounts";

const schema = {
  discount_id: "nuevo",
  title: "",
  code: "",
  description: "",
  amount: "",
  is_percent: true,
  start_date: "",
  expiration_date: "",
  limit_per_customer: "",
  available: true,
};

const DiscountsReducer = (state, { type, payload }) => {
  switch (type) {
    case DESCUENTOS_RECIBIDOS:
      return { ...state, descuentos: payload };
    case SET_DESCUENTO:
      return { ...state, descuento: payload };
    case SET_PROPIEDAD_DESCUENTO:
      const { key, value } = payload;
      const descuento = { ...state.descuento };
      descuento[key] = value;
      return { ...state, descuento };
    case CREATE_DESCUENTO:
      return { ...state, descuento: schema };
    case APPEND_PRODUCT_DISCOUNT: {
      let { discount_products } = state.descuento;
      if (!Array.isArray(discount_products)) {
        discount_products = [];
      }
      const index = discount_products.findIndex(
        ({ product_id }) => product_id === payload.product_id
      );
      if (index === -1) {
        discount_products.push(payload);
      }
      return { ...state, descuento: { ...state.descuento, discount_products } };
    }
    case REMOVE_PRODUCT_DISCOUNT: {
      let { discount_products } = state.descuento;
      if (!Array.isArray(discount_products)) {
        discount_products = [];
      }
      const index = discount_products.findIndex(
        ({ product_id }) => product_id === payload.product_id
      );
      if (index !== -1) {
        discount_products.splice(index, 1);
      }
      return { ...state, descuento: { ...state.descuento, discount_products } };
    }
    default:
      return { ...state };
  }
};

export default DiscountsReducer;
