import React, { useContext, useEffect } from "react";
import DiscountsTable from "../../components/descuentos/DiscountsTable";
import DescuentoForm from "../../components/descuentos/DescuentoForm";
import { DiscountsContext } from "../../context/DiscountsContext";
import { ModalContext } from "../../context/ModalContext";
import PanelTitle from "../../components/global/PanelTitle";

const AdminDescuentos = () => {
  const { descuentos, getDescuentosAdmin, deleteDescuento } =
    useContext(DiscountsContext);
  const { modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getDescuentosAdmin();
  }, []);

  const createDescuento = () => {
    modalComponent("Agregar Descuento", <DescuentoForm discount_id="nuevo" />);
  };

  const editarDescuento = (discount_id) => {
    modalComponent(
      "Editar Descuento",
      <DescuentoForm discount_id={discount_id} />
    );
  };

  const confirmDelete = (descuento) => {
    modalComponent(
      "Eliminar Descuento",
      <div>
        <p>
          ¿Estás segura que quieres eliminar el descuento {descuento.title} -{" "}
          {descuento.code}? Esta acción NO puede deshacerse.
        </p>
        <button
          className="btn btn-danger"
          onClick={() => deleteDescuento(descuento.discount_id)}
        >
          <i className="fa fa-trash"></i> Eliminar
        </button>
      </div>
    );
  };

  const renderDescuentos = () => {
    if (descuentos && descuentos !== null) {
      if (descuentos.length === 0) return <p>No hay descuentos registrados.</p>;
      return (
        <DiscountsTable
          discounts={descuentos}
          handleEdit={editarDescuento}
          handleDelete={confirmDelete}
        />
      );
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="container-fluid">
      <PanelTitle title="Descuentos" onClick={createDescuento} />
      <div className="card p-3 shadow">{renderDescuentos()}</div>
    </div>
  );
};

export default AdminDescuentos;
