import React from "react";
import CourseAccessUserRow from "./CourseAccessUserRow";

const CourseAccessesUserTable = ({
  order,
  setOrder,
  course_accesses,
  handleCallback,
}) => {
  const renderPurchases = () => {
    if (Array.isArray(course_accesses)) {
      if (course_accesses.length === 0) {
        return (
          <tr>
            <td colSpan={5}>No hay alumnas registradas.</td>
          </tr>
        );
      }
      return course_accesses.map((course_access) => (
        <CourseAccessUserRow
          handleCallback={handleCallback}
          key={course_access.course_access_id}
          course_access={course_access}
        />
      ));
    }
  };

  return (
    <div className="table-responsive">
      <table className="table border">
        <thead className="bg-light border bold small">
          <tr>
            <td>#ID</td>
            <td>Cliente</td>
            <td style={{ minWidth: 200 }}>Certificación</td>
            <td>Acceso Activado</td>
            <td>Examen Final</td>
            <td>
              Expiración Examen{" "}
              <i
                onClick={() => setOrder(order === "ASC" ? "DESC" : "ASC")}
                className={`fas fa-chevron-${order === "ASC" ? "up" : "down"}`}
              ></i>
            </td>
            <td>Terminó Examen</td>
            <td>Certificada</td>
            <td style={{ minWidth: 150 }}>Acciones</td>
          </tr>
        </thead>
        <tbody>{renderPurchases()}</tbody>
      </table>
    </div>
  );
};

export default CourseAccessesUserTable;
