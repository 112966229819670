export const getProductLocation = (product) => {
  let { location } = product;
  if (location !== null) {
    if (location === "studio") {
      location = "Monterrey, México";
    } else if (location === "remote") {
      location = "Zoom";
    } else if (typeof location === "object") {
      if (location.show_location_name) {
        if (location.address && location.address !== null) {
          location = `${location.name} @ ${location.address.city}, ${location.address.state}`;
        } else {
          location = location.name;
        }
      } else {
        if (location.address && location.address !== null) {
          location = `${location.address.city}, ${location.address.state}`;
        } else {
          location = location.description;
        }
      }
    }
  }
  return location;
};

export const getPrice = (product) => {
  if (product && product !== null) {
    const { sale_price, price } = product;
    const product_price = sale_price !== null ? sale_price : price;
    return product_price;
  }
};

export const hasSalePrice = (product) => {
  if (product && product !== null)
    return product.sale_price && product.sale_price !== null;
};