import React, { useState, useContext, useEffect } from "react";
import { CoursesContext } from "../../context/CoursesContext";

const PrerequisiteForm = ({ course_id, prerequisites, handleCancel }) => {
  const [courseId, setCourseId] = useState(null);
  const [availableCourses, setAvailableCourses] = useState(null);
  const { courses, getCourses, postCoursePrerequisite } =
    useContext(CoursesContext);

  useEffect(() => {
    getCourses();
  }, []);

  useEffect(() => {
    if (Array.isArray(courses) && !Array.isArray(availableCourses)) {
      let prerequisitesIds = prerequisites.map(({ course_id }) => course_id);
      let coursesRender = courses.filter(
        (course) =>
          !prerequisitesIds.includes(course.course_id) &&
          parseInt(course.course_id) !== parseInt(course_id)
      );
      setAvailableCourses(coursesRender);
    }
  }, [courses]);

  useEffect(() => {
    if (Array.isArray(availableCourses) && courseId === null) {
      if (availableCourses.length > 0) {
        setCourseId(availableCourses[0].course_id);
      }
    }
  }, [availableCourses]);

  const handleSubmit = (e) => {
    e.preventDefault();
    postCoursePrerequisite(course_id, courseId);
  };

  const renderCourses = () => {
    if (Array.isArray(courses)) {
      let prerequisitesIds = prerequisites.map(({ course_id }) => course_id);
      let coursesRender = courses.filter(
        (course) =>
          !prerequisitesIds.includes(course.course_id) &&
          parseInt(course.course_id) !== parseInt(course_id)
      );
      return coursesRender.map((course) => (
        <option key={course.course_id} value={course.course_id}>
          {course.name}
        </option>
      ));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>Curso</label>
      <select
        value={courseId === null ? "" : courseId}
        className="form-control mb-3"
        onChange={(e) => setCourseId(e.target.value)}
      >
        {renderCourses()}
      </select>
      <div className="row mt-3">
        <div className="col-6">
          <button className="btn text-muted w-100" onClick={handleCancel}>
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button
            type="submit"
            disabled={courseId === null}
            className="btn btn-primary w-100"
          >
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
};

export default PrerequisiteForm;
