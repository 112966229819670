import {
  ADDRESSES_RECEIVED,
  SET_ADDRESS,
  SET_PROPERTY_ADDRESS,
} from "../types/address";

const AddressReducer = (state, { type, payload }) => {
  switch (type) {
    case ADDRESSES_RECEIVED:
      return { ...state, addresses: payload };
    case SET_ADDRESS:
      return { ...state, address: payload };
    case SET_PROPERTY_ADDRESS:
      const address = { ...state.address };
      const { key, value } = payload;
      address[key] = value;
      return { ...state, address };
    default:
      return { ...state };
  }
};
export default AddressReducer;
