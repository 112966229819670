import { getArgs } from "../utils";
import api from "./api";

const route = "/invoices";

export default {
  getInvoicesAdmin: (startDate, endDate, args) =>
    api.get(
      `${route}/admin?start_date=${startDate}&end_date=${endDate}&${getArgs(
        args
      )}`
    ),
  postInvoice: (invoice) => api.post(route, { ...invoice }),
  putInvoice: (invoice) => api.put(route, { ...invoice }),
};
