import React from "react";
import DiscountRow from "./DiscountRow";

const DiscountsTable = ({ discounts, handleEdit, handleDelete }) => {
  const renderRows = () => {
    if (Array.isArray(discounts)) {
      return discounts.map((descuento) => (
        <DiscountRow
          key={descuento.discount_id}
          descuento={descuento}
          confirmDelete={handleDelete}
          editarDescuento={handleEdit}
        />
      ));
    }
  };

  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr className="bold bg-light border">
            <th>Código</th>
            <th>Cantidad</th>
            <th>Inicio</th>
            <th>Expiración</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </table>
    </div>
  );
};

export default DiscountsTable;
