import moment from "moment";
import React, { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import { CoursesContext } from "../../context/CoursesContext";

const CompletedCourses = ({ user_id, courses, handleCallback }) => {
  const { uncompleteCourse } = useContext(CoursesContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  const confirmDelete = (course) => {
    modalComponent(
      "Eliminar Completado",
      <div>
        <p>
          ¿Deseas desmarcar el curso {course.name} como completado para este
          usuario?
        </p>
        <div className="row">
          <div className="col-6">
            <button onClick={clearModal} className="btn text-muted w-100">
              Cancelar
            </button>
          </div>
          <div className="col-6">
            <button
              onClick={() =>
                uncompleteCourse(course.course_id, user_id, handleCallback)
              }
              className="btn btn-danger w-100"
            >
              <i className="fa fa-trash me-2"></i> Eliminar
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderCourses = () => {
    if (Array.isArray(courses)) {
      if (courses.length === 0) {
        return (
          <p className="p-2 mb-0">
            Este usuario aún no ha completado ningún curso.
          </p>
        );
      }
      return courses.map(({ course, createdAt }) => (
        <div key={course.course_id} className="row p-2 border mx-0">
          <div className="col-4">{course.name}</div>
          <div className="col-4">
            {moment(createdAt).utc().format("DD MMM YYYY")}
          </div>
          <div className="col-4">
            <button
              onClick={() => confirmDelete(course)}
              className="btn btn-sm btn-outline-danger p-1"
            >
              <i className="fa fa-trash"></i>
            </button>
          </div>
        </div>
      ));
    }
  };
  return (
    <div className="pb-3">
      <div className="row p-2 border mx-0 bold bg-light">
        <div className="col-4">Certificación</div>
        <div className="col-4">Fecha de Completado</div>
        <div className="col-4">Acciones</div>
      </div>
      {renderCourses()}
    </div>
  );
};

export default CompletedCourses;
