export const isAttemptValid = (attempt) => {
  return attempt && attempt !== null;
};
export const didNotRequestDiploma = (attempt) => {
  if (isAttemptValid(attempt))
    return !attempt.requested_diploma || attempt.requested_diploma === null;
};

export const hasFile = (attempt) => {
  if (isAttemptValid(attempt)) return attempt.file_id !== null;
};

export const isAttemptCompleted = (attempt) => {
  if (isAttemptValid(attempt)) return attempt.finishedAt !== null;
};
