import React, { createContext, useReducer, useContext } from "react";
import PaymentPlansService from "../services/PaymentPlansService";
import PaymentPlansReducer from "../reducers/PaymentPlansReducer";
import {
  PAYMENT_PLANS_RECEIVED,
  SET_PAYMENT_PLAN,
  CREATE_PAYMENT_PLAN,
  SET_PROPERTY_PAYMENT_PLAN,
} from "../types/payment_plans";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  payment_plans: null,
  payment_plan: null,
};

export const PaymentPlansContext = createContext(initialState);

export const PaymentPlansProvider = ({ children }) => {
  const [state, dispatch] = useReducer(PaymentPlansReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getPaymentPlans = () => {
    PaymentPlansService.getPaymentPlans()
      .then((response) => {
        const { payment_plans } = response.data;
        dispatch({ type: PAYMENT_PLANS_RECEIVED, payload: payment_plans });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSinglePayment_plan = (payment_plan_id) => {
    PaymentPlansService.getSinglePayment_plan(payment_plan_id)
      .then((response) => {
        const { payment_plan } = response.data;
        dispatch({ type: SET_PAYMENT_PLAN, payload: payment_plan });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setPayment_plan = (payment_plan) => {
    dispatch({ type: SET_PAYMENT_PLAN, payload: payment_plan });
  };

  const createPayment_plan = () => {
    dispatch({ type: CREATE_PAYMENT_PLAN });
  };

  const setPropertyPayment_plan = (key, value) => {
    dispatch({ type: SET_PROPERTY_PAYMENT_PLAN, payload: { key, value } });
  };

  const savePayment_plan = (payment_plan, callback) => {
    dispatch({ type: SHOW_SPINNER });
    let service = PaymentPlansService.putPayment_plan;
    if (isNaN(parseInt(payment_plan.payment_plan_id))) {
      service = PaymentPlansService.postPayment_plan;
    }
    service(payment_plan)
      .then(() => {
        success("Payment_plan saved.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deletePayment_plan = (payment_plan_id, callback) => {
    dispatch({ type: SHOW_SPINNER });
    PaymentPlansService.deletePayment_plan(payment_plan_id)
      .then(() => {
        success("Payment_plan deleted.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  return (
    <PaymentPlansContext.Provider
      value={{
        ...state,
        setPayment_plan,
        getPaymentPlans,
        savePayment_plan,
        deletePayment_plan,
        createPayment_plan,
        getSinglePayment_plan,
        setPropertyPayment_plan,
      }}
    >
      {children}
    </PaymentPlansContext.Provider>
  );
};
