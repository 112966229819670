import React from "react";

const DeleteAttemptForm = ({ handleCancel, handleSubmit }) => {
  return (
    <div>
      <p className="bold">
        ¿Deseas eliminar este intento de examen? La alumna tendrá que volver a
        comenzar un nuevo examen y subir sus videos.
      </p>
      <p>Esta acción NO puede deshacerse.</p>
      <div className="row">
        <div className="col-6">
          <button onClick={handleCancel} className="btn text-muted w-100">
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button onClick={handleSubmit} className="btn btn-primary w-100">
            Eliminar Examen
          </button>
        </div>
      </div>
    </div>
  );
};
export default DeleteAttemptForm;
