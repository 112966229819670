import React, { useState, useContext } from "react";
import { AttemptsContext } from "../../context/AttemptsContext";

const AttemptTrackingNumber = ({ type }) => {
  const [isFile, setIsFile] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const { attempt, saveAttempt, setPropertyAttempt } =
    useContext(AttemptsContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    setEditMode(false);
    saveAttempt(attempt);
  };

  const handleTrackingNumberChange = e => {
    let value = e.target.value;
    if(isFile) {
      value = e.target.files[0];
    }
    setPropertyAttempt("tracking_number", value);
  }

  const renderForm = () => {
    if (editMode) {
      return (
        <form onSubmit={handleSubmit}>
          <div className="btn-group mb-3">
            <button type="button" onClick={() => setIsFile(false)} className={`btn btn-${isFile ? "outline-primary" : "primary"}`}>
              Enlace
            </button>
            <button type="button" onClick={() => setIsFile(true)} className={`btn btn-${!isFile ? "outline-primary" : "primary"}`}>
              Archivo
            </button>
          </div>
          <input
            type={isFile ? "file" : "text"}
            value={isFile ? "" : attempt.tracking_number}
            className="form-control mb-3"
            placeholder="Pega la liga de rastreo"
            onChange={handleTrackingNumberChange}
          />
          <div className="row">
            <div className="col-6">
              <button
                type="button"
                className="btn w-100 text-muted"
                onClick={() => setEditMode(false)}
              >
                Cancelar
              </button>
            </div>
            <div className="col-6">
              <button type="submit" className="btn w-100 btn-primary">
                <i className="fa fa-save me-2"></i> Guardar
              </button>
            </div>
          </div>
        </form>
      );
    }
    if (attempt.tracking_number !== null) {
      return (
        <div>
          <a
            rel="noreferrer"
            target="_blank"
            href={attempt.tracking_number}
            className="btn px-0 btn-link"
          >
            Rastrear Envío de {type} Físico
          </a>
          <button
            onClick={() => setEditMode(true)}
            className="btn d-block btn-outline-dark mt-3"
          >
            <i className="fa fa-edit me-1"></i> Editar
          </button>
        </div>
      );
    }
    return (
      <div>
        <p>No has agregado guía de envío.</p>
        <button
          onClick={() => setEditMode(true)}
          className="btn btn-outline-dark mt-3"
        >
          <i className="fa fa-edit me-2"></i> Editar
        </button>
      </div>
    );
  };

  return (
    <div>
      <h3>{type} Físico</h3>
      {renderForm()}
    </div>
  );
};

export default AttemptTrackingNumber;
