import React from "react";

const ProductMode = ({ product }) => {
  const renderBadge = () => {
    return (
      <span
        className={`badge badge-pill text-uppercase text-dark me-2 ${
          product.mode === "on-demand" ? "bg-primary" : "bg-accent"
        }`}
      >
        {product.mode === "live" ? "En Vivo" : "On Demand"}
      </span>
    );
  };
  return <span>{renderBadge()}</span>;
};

export default ProductMode;
