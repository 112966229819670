import React, { useContext, useEffect, useState } from "react";
import { ProductsContext } from "../../context/ProductsContext";
import ProductsTable from "../../components/products/ProductsTable";
import PanelTitle from "../../components/global/PanelTitle";
import { navigate } from "@reach/router";

const AdminProducts = () => {
  const [query, setQuery] = useState("");
  const { products, getAllProducts } = useContext(ProductsContext);

  useEffect(() => {
    getAllProducts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderProducts = () => {
    if (products && products !== null) {
      let productsRender = products;
      if (query !== "") {
        productsRender = productsRender.filter((product) =>
          product.title.toLowerCase().includes(query)
        );
      }
      if (productsRender.length === 0) {
        return <p>No hay products disponibles.</p>;
      }
      return (
        <ProductsTable
          products={productsRender}
          handleCallback={getAllProducts}
        />
      );
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="container-fluid">
      <PanelTitle
        title="Productos"
        onClick={() => navigate("/myadmin/products/nuevo")}
      />
      <div className="container-fluid px-0 mb-3">
        <input
          type="text"
          value={query}
          className="form-control"
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Buscar producto por nombre..."
        />
      </div>
      <div className="card p-3 shadow">{renderProducts()}</div>
    </div>
  );
};

export default AdminProducts;
