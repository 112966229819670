import {
  CREATE_COURSE,
  COURSES_RECIBIDOS,
  SET_PROPERTY_COURSE,
  SINGLE_COURSE_RECIBIDO,
  APPEND_COURSE_PREREQUISITE,
} from "../types/courses";

const schema = {
  course_id: "nuevo",
  name: "",
  description: "",
  expiration_days: "",
};

export default (state, { type, payload }) => {
  switch (type) {
    case COURSES_RECIBIDOS:
      return { ...state, courses: payload };
    case SINGLE_COURSE_RECIBIDO:
      return { ...state, course: payload };
    case SET_PROPERTY_COURSE:
      const { key, value } = payload;
      const course = { ...state.course };
      course[key] = value;
      return { ...state, course };
    case CREATE_COURSE:
      return { ...state, course: schema };
    case APPEND_COURSE_PREREQUISITE: {
      let { prerequisites } = state.course;
      if (!Array.isArray(prerequisites)) {
        prerequisites = [];
      }
      const index = prerequisites.findIndex(
        ({ course_id }) => parseInt(course_id) === parseInt(payload.course_id)
      );
      if (index === -1) {
        prerequisites.push(payload);
      }
      return { ...state, course: { ...state.course, prerequisites } };
    }
    default:
      return { ...state };
  }
};
