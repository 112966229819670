import React from "react";
import { Link } from "@reach/router";
import { S3_ENDPOINT } from "../../utils";

const SectionRow = ({ section }) => {
  const getSrc = () => {
    const { file } = section;
    let src = "tbm-cert-logo.png";
    if (file && file !== null) {
      src = `${file.name}.${file.type}`;
    }
    return `${S3_ENDPOINT}/${src}`;
  };
  return (
    <tr className="small bg-white border-bottom align-middle hover-light">
      <td>#{section.section_id}</td>
      <td>
        <Link to={`/myadmin/sections/${section.section_id}`}>
          {section.name}
        </Link>
      </td>
      <td className="wide">{section.description}</td>
    </tr>
  );
};

export default SectionRow;
