import {
  CREATE_SECTION,
  SECTIONS_RECIBIDOS,
  SET_SECTION,
  SET_PROPERTY_SECTION,
} from "../types/sections";

const schema = {
  section_id: "nuevo",
  titulo: "",
  descripcion: "",
  idAdjunto: null,
  file: null,
  orden: "",
  activo: true,
};
export default (state, { type, payload }) => {
  switch (type) {
    case CREATE_SECTION:
      let sections = state.sections;
      if (sections === null) sections = [];
      const current = { ...schema, section_id: `nuevo-${sections.length}` };
      return { ...state, sections: [...sections, current], section: current };
    case SECTIONS_RECIBIDOS:
      return { ...state, sections: payload };
    case SET_SECTION:
      return { ...state, section: payload };
    case SET_PROPERTY_SECTION:
      const section = { ...state.section };
      const { key, value } = payload;
      section[key] = value;
      return { ...state, section };
    default:
      return { ...state };
  }
};
