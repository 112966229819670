import React from "react";

const ConfirmDeleteAccess = ({
  customer,
  course_access,
  handleDelete,
  handleCancel,
  handleCallback,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    handleDelete(course_access.course_access_id, handleCallback);
  };

  return (
    <form onSubmit={handleSubmit}>
      <p>
        ¿Estás segura que deseas eliminar el #{course_access.course_access_id}{" "}
        {course_access.course !== null
          ? ` que da acceso al curso ${course_access.course.name} `
          : ""}
        para {customer.name}?
      </p>
      <p>Esta acción NO puede deshacerse y perderá acceso inmediatamente.</p>
      <div className="row mt-3 align-items-center">
        <div className="col-6">
          <button onClick={handleCancel} className="btn w-100 text-muted">
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button type="submit" className="btn w-100 btn-danger">
            Eliminar
          </button>
        </div>
      </div>
    </form>
  );
};
export default ConfirmDeleteAccess;
