import React, { useState, useContext, useEffect } from "react";
import { CourseAccessContext } from "../../context/CourseAccessContext";
import { getUserFinalExamAttempt } from "../../utils/course_access";
import { CustomerContext } from "../../context/CustomerContext";
import { ModalContext } from "../../context/ModalContext";
import { UserContext } from "../../context/UserContext";
import ConfirmDeleteAccess from "./ConfirmDeleteAccess";
import { getLocationName } from "../../utils/location";
import CourseAccessForm from "./CourseAccessForm";
import { Link } from "@reach/router";
import moment from "moment";
import {
  didNotRequestDiploma,
  isAttemptCompleted,
  hasFile,
} from "../../utils/attempts";

const CourseAccessUserRow = ({ course_access, handleCallback }) => {
  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);

  const { user } = useContext(UserContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { customer, getCustomer, clearCustomer } = useContext(CustomerContext);

  const { setCourseAccess, deleteCourseAccess } =
    useContext(CourseAccessContext);

  useEffect(() => {
    if (customer !== null) {
      if (editMode) {
        modalComponent(
          "Editar Compra",
          <CourseAccessForm
            purchases={customer.purchases}
            handleCallback={handleCallback}
            handleCancel={handleCancel}
          />
        );
      } else if (deleteMode) {
        modalComponent(
          "Eliminar Acceso",
          <ConfirmDeleteAccess
            handleDelete={deleteCourseAccess}
            handleCallback={handleCallback}
            course_access={course_access}
            handleCancel={handleCancel}
            customer={customer}
          />
        );
      }
    }
  }, [customer]);

  const handleCancel = () => {
    setCourseAccess(null);
    setDeleteMode(false);
    setEditMode(false);
    clearCustomer();
    clearModal();
  };

  const handleEdit = () => {
    getCustomer(course_access.user_id);
    setCourseAccess(course_access);
    setEditMode(true);
  };

  const handleDelete = () => {
    getCustomer(course_access.user_id);
    setCourseAccess(course_access);
    setDeleteMode(true);
  };

  const canEdit = () => {
    return ["admin", "manager"].includes(user.role);
  };

  const renderActions = () => {
    if (canEdit()) {
      return (
        <div>
          <button
            className="btn btn-sm btn-outline-dark me-2"
            onClick={handleEdit}
          >
            <i className="fa fa-edit"></i>
          </button>
          <button
            className="btn btn-sm btn-outline-danger me-2"
            onClick={handleDelete}
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      );
    }
  };

  const renderCustomer = () => {
    if (course_access.user && course_access.user !== null) {
      return (
        <Link to={`/myadmin/customer/${course_access.user.user_id}`}>
          <i className="fa fa-eye"></i> {course_access.user.name}{" "}
          {course_access.user.last_name}
        </Link>
      );
    }
  };

  const renderBadge = () => {
    const { purchase } = course_access;
    if (purchase && purchase !== null) {
      const { product } = purchase;
      if (product && product !== null) {
        return (
          <span
            className={`badge badge-pill text-uppercase text-dark ${
              product.mode === "on-demand" ? "bg-primary" : "bg-accent"
            }`}
          >
            {product.mode === "live" ? "En Vivo" : "On Demand"}
          </span>
        );
      }
    }
  };

  const renderDates = () => {
    const { start_date, end_date } = course_access.purchase.product;
    if (start_date !== null && end_date !== null) {
      return (
        <p className="mb-0 text-muted small">
          <i className="fa fa-calendar me-2"></i>
          {moment(start_date).format("DD MMM YYYY")} -{" "}
          {moment(end_date).format("DD MMM YYYY")}
        </p>
      );
    }
  };

  const renderCourse = () => {
    if (course_access.course && course_access.course !== null) {
      return (
        <div>
          <p className="mb-1">
            {renderBadge()} - {course_access.course.name}
          </p>
          <p className="mb-0 small text-muted">
            {getLocationName(course_access.purchase.product.location)}
          </p>
          {renderDates()}
        </div>
      );
    }
  };

  const renderExamExpiration = () => {
    if (course_access.expiration_date === null) {
      return "Sin expiración";
    }
    let expiration_date = moment(course_access.expiration_date)
      .utc()
      .endOf("day")
      .add(7, "days");
    const expiration_date_string = expiration_date.format("DD MMM YYYY HH:mm");
    if (expiration_date.isAfter(moment())) {
      if (moment().add(7, "days").isSameOrAfter(expiration_date)) {
        return (
          <div>
            <span className={`badge badge-pill bg-warning text-dark`}>
              Por Vencer
            </span>
            <p className="mb-0">{expiration_date_string}</p>
          </div>
        );
      }
      return (
        <div>
          <span className={`badge badge-pill bg-success`}>Vigente</span>
          <p className="mb-0">{expiration_date_string}</p>
        </div>
      );
    }
    return (
      <div>
        <span className={`badge badge-pill bg-danger`}>Expirado</span>
        <p className="mb-0">{expiration_date_string}</p>
      </div>
    );
  };

  const renderFinalExam = () => {
    if (course_access.final_exam && course_access.final_exam !== null) {
      return course_access.final_exam;
    }
  };

  const renderCertified = () => {
    const attempt = getUserFinalExamAttempt(course_access);
    if (hasFile(attempt) && didNotRequestDiploma(attempt)) {
      return <i className="fa fa-check text-success"></i>;
    }
  };

  const renderExam = () => {
    const attempt = getUserFinalExamAttempt(course_access);
    if (isAttemptCompleted(attempt)) {
      return <i className="fa fa-check text-success"></i>;
    }
  };

  const renderStartDate = () => {
    let { start_date } = course_access;
    if (!start_date || start_date === null) {
      start_date = course_access.createdAt;
    }
    return moment(start_date).utc().format("DD MMM YYYY");
  };

  return (
    <tr className="border-bottom align-middle hover-light">
      <td className="small">
        {course_access.course_access_id}{" "}
        {course_access.enabled_by !== null && (
          <i className="fa fa fa-user-shield"></i>
        )}
      </td>
      <td className="wide">{renderCustomer()}</td>
      <td className="wide">{renderCourse()}</td>
      <td className="wide">{renderStartDate()}</td>
      <td className="wide">{renderFinalExam()}</td>
      <td className="wide">{renderExamExpiration()}</td>
      <td className="wide">{renderExam()}</td>
      <td>{renderCertified()}</td>
      <td>{renderActions()}</td>
    </tr>
  );
};

export default CourseAccessUserRow;
