import React, { useContext } from "react";
import { LessonsContext } from "../../context/LessonsContext";

const LessonMediaForm = ({ handleCancel, handleSubmit }) => {
  const { spinner, media, setPropertyMedia } = useContext(LessonsContext);

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(media);
  };

  const handleChange = (key, value) => {
    setPropertyMedia(key, value);
  };

  const handleUrlModifier = (e) => {
    let { value } = e.target;
    value = String(value);
    if (value.includes("vimeo")) {
      let current = value.split("https://vimeo.com/")[1];
      if (current) {
        current = String(current);
        value = current.replace("/", "?h=");
        value = value.replace("?share=copy", "");
      }
    }
    handleChange("src", value);
  };

  const renderSource = () => {
    if (media.type !== "file") {
      return (
        <div>
          <label>Enlace</label>
          <input
            type="text"
            value={media.src}
            className="form-control mb-3"
            onChange={handleUrlModifier}
          />
        </div>
      );
    }
  };
  const renderFile = () => {
    if (media.type === "file" || media.type === null) {
      return (
        <div>
          <label>Archivo</label>
          <input
            type="file"
            className="form-control mb-3"
            onChange={(e) => handleChange("file", e.target.files[0])}
          />
        </div>
      );
    }
  };
  return (
    <form onSubmit={onSubmit}>
      <label>Nombre</label>
      <input
        type="text"
        value={media.name}
        className="form-control mb-3"
        onChange={(e) => handleChange("name", e.target.value)}
      />
      <label>Descripción</label>
      <input
        type="text"
        value={media.description}
        className="form-control mb-3"
        onChange={(e) => handleChange("description", e.target.value)}
      />
      <label>Tipo</label>
      <select
        className="form-control mb-3"
        value={media.type}
        onChange={(e) => handleChange("type", e.target.value)}
      >
        <option value="vimeo">Vimeo</option>
        <option value="youtube">YouTube</option>
        <option value="link">Enlace</option>
        <option value="file">Documento / Imagen</option>
      </select>
      {renderSource()}
      {renderFile()}
      <div className="row align-items-center">
        <div className="col-6">
          <button type="submit" className="btn btn-primary" disabled={spinner}>
            {spinner ? <div className="spinner-border"></div> : "Guardar"}
          </button>
        </div>
        <div className="col-6 text-end">
          <button
            type="button"
            className="btn btn-link text-muted"
            onClick={handleCancel}
          >
            Cancelar
          </button>
        </div>
      </div>
    </form>
  );
};

export default LessonMediaForm;
