import { navigate } from "@reach/router";
import React, { createContext, useContext, useReducer } from "react";
import CoursesReducer from "../reducers/CoursesReducer";
import CoursesService from "../services/CoursesService";
import {
  CREATE_COURSE,
  COURSES_RECIBIDOS,
  SET_PROPERTY_COURSE,
  SINGLE_COURSE_RECIBIDO,
} from "../types/courses";
import { SHOW_SPINNER, HIDE_SPINNER } from "../types";
import { ModalContext } from "./ModalContext";

const initialState = {
  spinner: false,
  courses: null,
  course: null,
};

export const CoursesContext = createContext(initialState);

export const CoursesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CoursesReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getCourses = () => {
    CoursesService.getCourses().then((res) => {
      const { courses } = res.data;
      dispatch({ type: COURSES_RECIBIDOS, payload: courses });
    });
  };

  const getSingleCourse = (course_id) => {
    CoursesService.getSingleCourse(course_id)
      .then((res) => {
        const { course } = res.data;
        dispatch({ type: SINGLE_COURSE_RECIBIDO, payload: course });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 412) {
            alert("Necesitas comprar este curso para acceder al contenido.");
            navigate(`/checkout/course/${course_id}`);
          }
        }
      });
  };

  const clearCourse = () => {
    dispatch({ type: SINGLE_COURSE_RECIBIDO, payload: null });
  };

  const createCourse = () => {
    dispatch({ type: CREATE_COURSE });
  };

  const setPropiedadCourse = (key, value) => {
    dispatch({ type: SET_PROPERTY_COURSE, payload: { key, value } });
  };

  const postCourse = (course) => {
    dispatch({ type: SHOW_SPINNER });
    dispatch({ type: HIDE_SPINNER });
  };

  const completeCourse = (course_id, user_id, callback) => {
    CoursesService.completeCourse(course_id, user_id).then(() => {
      success("Certificación agregada.");
      if (typeof callback === "function") {
        callback();
      }
      clearModal();
    });
  };

  const uncompleteCourse = (course_id, user_id, callback) => {
    CoursesService.uncompleteCourse(course_id, user_id).then(() => {
      success("Certificación eliminada.");
      if (typeof callback === "function") {
        callback();
      }
      clearModal();
    });
  };

  const postCoursePrerequisite = (course_id, prerequisite_course_id) => {
    CoursesService.postCoursePrerequisite(
      course_id,
      prerequisite_course_id
    ).then(() => {
      success("Prerequisito agregado!");
      getSingleCourse(course_id);
      clearModal();
    });
  };

  const deleteCoursePrerequisite = (course_id, prerequisite_course_id) => {
    CoursesService.deleteCoursePrerequisite(
      course_id,
      prerequisite_course_id
    ).then(() => {
      success("Prerequisito eliminado!");
      getSingleCourse(course_id);
      clearModal();
    });
  };

  return (
    <CoursesContext.Provider
      value={{
        ...state,
        postCourse,
        getCourses,
        clearCourse,
        createCourse,
        completeCourse,
        getSingleCourse,
        uncompleteCourse,
        setPropiedadCourse,
        postCoursePrerequisite,
        deleteCoursePrerequisite,
      }}
    >
      {children}
    </CoursesContext.Provider>
  );
};
