import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import {
  CREATE_LESSON,
  CREATE_MEDIA,
  LESSONS_RECIBIDAS,
  SET_LESSON,
  SET_LESSON_MEDIA,
  SET_LESSON_RESOURCE,
  SET_PROPERTY_DESCARGABLE,
  SET_PROPERTY_LESSON,
  SET_PROPERTY_MULTIMEDIA,
} from "../types/lessons";

const mediaSchema = {
  lesson_media_id: "nueva",
  name: "",
  description: "",
  src: "",
  type: "vimeo",
  file: null,
};

const lessonSchema = {
  lesson_id: "nueva",
  name: "",
  description: "",
};

export default (state, { type, payload }) => {
  switch (type) {
    case SET_LESSON:
      return { ...state, lesson: payload };
    case LESSONS_RECIBIDAS:
      return { ...state, lessons: payload };
    case SET_LESSON_MEDIA:
      return { ...state, media: payload };
    case SET_LESSON_RESOURCE:
      return { ...state, resource: payload };
    case SET_PROPERTY_MULTIMEDIA: {
      const { media } = { ...state };
      const { key, value } = payload;
      media[key] = value;
      return { ...state, media };
    }
    case SET_PROPERTY_DESCARGABLE: {
      const { resource } = { ...state };
      const { key, value } = payload;
      resource[key] = value;
      return { ...state, resource };
    }
    case CREATE_MEDIA:
      return { ...state, media: mediaSchema };
    case CREATE_LESSON:
      return { ...state, lesson: lessonSchema };
    case SET_PROPERTY_LESSON: {
      const { lesson } = { ...state };
      const { key, value } = payload;
      lesson[key] = value;
      return { ...state, lesson };
    }
    case SET_LESSON:
      return { ...state, lesson: payload };
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    default:
      return { ...state };
  }
};
