import React, { useContext } from "react";
import PanelTitle from "../global/PanelTitle";
import PrerequisiteForm from "./PrequisiteForm";
import PrerequisiteRow from "./PrerequisiteRow";
import { ModalContext } from "../../context/ModalContext";

const PrerequisitesTable = ({ course_id, prerequisites }) => {
  const { clearModal, modalComponent } = useContext(ModalContext);

  const handleCreatePreequisite = () => {
    modalComponent(
      "Agregar Prerequisito",
      <PrerequisiteForm
        prerequisites={prerequisites}
        handleCancel={clearModal}
        course_id={course_id}
      />
    );
  };

  const renderRows = () => {
    if (Array.isArray(prerequisites)) {
      if (prerequisites.length === 0) {
        return <p>No has agregado prerequisitos.</p>;
      }
      return prerequisites.map((course) => (
        <PrerequisiteRow
          key={course.course_id}
          course_id={course_id}
          course={course}
        />
      ));
    }
  };
  return (
    <div className="card p-3 mb-3">
      <PanelTitle title="Prerequisitos" onClick={handleCreatePreequisite} />
      <div className="container-fluid">{renderRows()}</div>
    </div>
  );
};

export default PrerequisitesTable;
