import React, { useContext, useEffect, useState } from "react";
import { PaymentMethodsContext } from "../../context/PaymentMethodsContext";
import Switch from "react-switch";
import { ProductsContext } from "../../context/ProductsContext";
import { PurchasesContext } from "../../context/PurchasesContext";
import moment from "moment";
import { getLocationName } from "../../utils/location";

const AddPurchaseForm = ({ customer, handleCancel }) => {
  const [gift, setGift] = useState(false);
  const [amount, setAmount] = useState("");
  const [product, setProduct] = useState(null);
  const [productId, setProductId] = useState(null);
  const [status, setStatus] = useState("completed");
  const [paymentMethod, setPaymentMethod] = useState(null);

  const { products, getProducts } = useContext(ProductsContext);
  const { savePurchase } = useContext(PurchasesContext);
  const { payment_methods, getPaymentMethods } = useContext(
    PaymentMethodsContext
  );

  useEffect(() => {
    getProducts();
    getPaymentMethods();
  }, []);

  useEffect(() => {
    if (productId !== null) {
      const current = products.find(
        (product) => parseInt(product.product_id) === parseInt(productId)
      );
      if (current) {
        setAmount(current.price);
      }
    }
  }, [productId]);

  useEffect(() => {
    if (products && products !== null && product === null) {
      setProduct(products[0]);
      setProductId(products[0].product_id);
    }
  }, [products]);

  useEffect(() => {
    if (product !== null) {
      setPaymentMethod(1);
    }
  }, [product]);

  const handleSubmit = (e) => {
    e.preventDefault();
    savePurchase({
      status,
      is_gift: gift,
      product_id: productId,
      amount: gift ? 0 : amount,
      user_id: customer.user_id,
      payment_method_id: paymentMethod,
    });
  };

  const renderDates = (product) => {
    if (product.start_date !== null && product.end_date !== null) {
      return ` - ${moment(product.start_date).format("DD MMM YY")} - 
          ${moment(product.end_date).format("DD MMM YY")}`;
    }
  };

  const renderProductos = () => {
    if (products && products !== null) {
      return products.map((product) => (
        <option key={product.product_id} value={product.product_id}>
          {product.name}{" - "}
          {String(product.mode)[0]
            .toUpperCase()
            .concat(String(product.mode).slice(1))}
          {renderDates(product)}{" - "}{getLocationName(product.location)}
        </option>
      ));
    }
  };

  const renderPaymentMethods = () => {
    if (Array.isArray(payment_methods)) {
      return payment_methods.map((payment_method) => (
        <option
          key={payment_method.payment_method_id}
          value={payment_method.payment_method_id}
        >
          {payment_method.name}
        </option>
      ));
    }
  };

  return (
    <div className="container-fluid px-0">
      <form onSubmit={handleSubmit}>
        <label>Producto</label>
        <select
          className="form-control mb-3"
          onChange={(e) => setProductId(e.target.value)}
        >
          {renderProductos()}
        </select>

        <label className="d-block">¿Es Regalo?</label>
        <Switch
          checked={gift}
          className="d-block mt-1 mb-3"
          onChange={(checked) => setGift(checked)}
        />
        {!gift && (
          <div>
            <label>Total Pagado</label>
            <input
              step=".01"
              type="number"
              value={amount}
              className="form-control mb-3"
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>
        )}
        <label>Estado</label>
        <select
          value={status}
          className="form-control mb-3"
          onChange={(e) => setStatus(e.target.value)}
        >
          <option value="completed">Completada</option>
          <option value="pending">Pendiente</option>∫
        </select>
        {!gift && (
          <div>
            <label className="d-block">Método de Pago</label>
            <select
              className="form-control mb-3"
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
            >
              {renderPaymentMethods()}
            </select>
          </div>
        )}
        <div className="row mt-3">
          <div className="col-6">
            <button
              type="button"
              onClick={handleCancel}
              className="btn w-100 text-muted"
            >
              Cancelar
            </button>
          </div>
          <div className="col-6">
            <input
              type="submit"
              className="btn w-100 btn-primary"
              value="Guardar"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddPurchaseForm;
