import React, { useContext, useEffect } from "react";
import ReactSwitch from "react-switch";
import { ProductsContext } from "../../context/ProductsContext";
import { PaymentPlansContext } from "../../context/PaymentPlansContext";

const ProductPaymentPlans = () => {
  const { payment_plans, getPaymentPlans } = useContext(PaymentPlansContext);
  const { product, setPropertyProduct } = useContext(ProductsContext);

  useEffect(() => {
    getPaymentPlans();
  }, []);

  const hasPaymentPlan = (payment_plan) => {
    if (Array.isArray(product.payment_plans)) {
      return (
        product.payment_plans.findIndex(
          ({ payment_plan_id }) =>
            parseInt(payment_plan_id) === parseInt(payment_plan.payment_plan_id)
        ) !== -1
      );
    }
  };

  const handlePaymentPlan = (payment_plan) => {
    let currentPlans = [...product.payment_plans];
    if (hasPaymentPlan(payment_plan)) {
      const index = currentPlans.findIndex(
        ({ payment_plan_id }) =>
          parseInt(payment_plan_id) === parseInt(payment_plan.payment_plan_id)
      );
      if (index !== -1) {
        currentPlans.splice(index, 1);
      }
    } else {
      currentPlans.push(payment_plan);
    }
    setPropertyProduct("payment_plans", currentPlans);
  };

  const renderPaymentPlans = () => {
    if (Array.isArray(payment_plans)) {
      return payment_plans.map((payment_plan) => (
        <div className="row mt-2 mb-3" key={payment_plan.payment_plan_id}>
          <label className="d-flex align-items-center">
            <ReactSwitch
              checked={hasPaymentPlan(payment_plan)}
              onChange={() => handlePaymentPlan(payment_plan)}
            />{" "}
            <span className="ms-2">{payment_plan.name}</span>
          </label>
        </div>
      ));
    }
  };

  return (
    <div className="container-fluid px-0">
      <h3>Planes de Pago</h3>
      {renderPaymentPlans()}
    </div>
  );
};

export default ProductPaymentPlans;
