import api from "./api";

const route = "/payment_plans";

export default {
  getPaymentPlans: () => api.get(route),
  getSinglePayment_plan: (payment_plan_id) =>
    api.get(`${route}/${payment_plan_id}`),
  postPayment_plan: (payment_plan) => api.post(route, { ...payment_plan }),
  putPayment_plan: (payment_plan) => api.put(route, { ...payment_plan }),
  deletePayment_plan: (Payment_plan) => api.delete(`${route}/${Payment_plan}`),
};
