import React, { useContext } from "react";
import { LessonsContext } from "../../context/LessonsContext";
import ImageInput from "../common/ImageInput";
import { S3_ENDPOINT } from "../../utils";

const LessonForm = ({ handleSubmit, handleCancel }) => {
  const { spinner, lesson, setPropertyLesson } = useContext(LessonsContext);

  const getSrc = () => {
    if (lesson.file && lesson.file !== null) {
      return `${S3_ENDPOINT}/${lesson.file.name}.${lesson.file.type}`;
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(lesson);
  };

  return (
    <form onSubmit={onSubmit}>
      <ImageInput
        value={getSrc()}
        label="Thumbnail"
        prop="picture"
        modifier={setPropertyLesson}
      />
      <label className="d-block">Nombre</label>
      <input
        type="text"
        value={lesson.name}
        className="form-control mb-3"
        onChange={(e) => setPropertyLesson("name", e.target.value)}
      />
      <label className="d-block">Descripción</label>
      <input
        type="text"
        value={lesson.description}
        className="form-control mb-3"
        onChange={(e) => setPropertyLesson("description", e.target.value)}
      />
      <div className="row">
        <div className="col-6">
          <button type="submit" disabled={spinner} className="btn btn-primary">
            {spinner ? <div className="spinner-border"></div> : "Guardar"}
          </button>
        </div>
        <div className="col-6 text-end">
          <button
            type="button"
            className="btn btn-link text-muted"
            onClick={handleCancel}
          >
            Cancelar
          </button>
        </div>
      </div>
    </form>
  );
};

export default LessonForm;
