import React, { useContext } from "react";
import { CoursesContext } from "../../context/CoursesContext";
import { ModalContext } from "../../context/ModalContext";

const PrerequisiteRow = ({ course_id, course }) => {
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { deleteCoursePrerequisite } = useContext(CoursesContext);

  const handleConfirmDelete = (course) => {
    modalComponent(
      "Eliminar Prerequisito",
      <div>
        <p>¿Estás segura que deseas eliminar este prerrequisito?</p>
        <div className="row">
          <div className="col-6">
            <button onClick={clearModal} className="btn w-100 text-muted">
              Cancelar
            </button>
          </div>
          <div className="col-6">
            <button
              className="btn w-100 btn-danger"
              onClick={() =>
                deleteCoursePrerequisite(course_id, course.course_id)
              }
            >
              Eliminar
            </button>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div
      key={course.course_id}
      className="row align-items-center py-2 border-bottom"
    >
      <div className="col">{course.name}</div>
      <div className="col">
        <button
          onClick={() => handleConfirmDelete(course)}
          className="btn btn-sm btn-outline-danger"
        >
          <i className="fa fa-trash"></i>
        </button>
      </div>
    </div>
  );
};

export default PrerequisiteRow;
