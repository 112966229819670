import React, { useContext, useEffect, useState } from "react";
import { CoachesContext } from "../../context/CoachesContext";
import { VideosContext } from "../../context/VideosContext";

const FilterSection = () => {
  const [query, setQuery] = useState("");
  const [coach, setCoach] = useState("");

  const { coaches, getCoaches } = useContext(CoachesContext);

  const { getAllVideos, clearVideos } = useContext(VideosContext);

  useEffect(() => {
    getCoaches();
  }, []);

  useEffect(() => {
    clearVideos();
    getAllVideos({
      instructor_id: coach,
      section_id: 4,
      query,
    });
  }, [query, coach]);

  const renderFilters = () => {
    return (
      <div className="container-fluid">
        <label className="mb-1">Coach</label>
        <select
          value={coach}
          className="form-control"
          onChange={(e) => setCoach(e.target.value)}
        >
          {renderCoaches()}
        </select>
      </div>
    );
  };

  const renderCoaches = () => {
    if (coaches && coaches !== null) {
      return [
        <option key="0" value="">
          Todo
        </option>,
        ...coaches.map((coach) => (
          <option key={coach.instructor_id} value={coach.instructor_id}>
            {coach.name} {coach.last_name}
          </option>
        )),
      ];
    }
  };

  return (
    <div className="filter-card card no-scale shadow-sm">
      <div className="row">
        <div className="col-12 col-md-6">
          <h5 className="mb-1">Filtros</h5>
          <input
            type="text"
            value={query}
            className="form-control mb-3"
            placeholder="Buscar por nombre..."
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        <div className="col-12 col-md-6">{renderFilters()}</div>
      </div>
    </div>
  );
};

export default FilterSection;
