import { Link } from "@reach/router";
import React, { useContext } from "react";
import FilterSection from "../../components/videos/FilterSection";
import VideoCard from "../../components/videos/VideoCard";
import { VideosContext } from "../../context/VideosContext";

const AdminVideos = () => {
  const { videos } = useContext(VideosContext);

  const renderVideos = () => {
    if (videos && videos !== null) {
      let videosRender = videos;
      return videosRender.map((video) => (
        <VideoCard key={video.video_id} video={video} disableHover editable />
      ));
    }
    return <div className="spinner-border mx-3"></div>;
  };

  return (
    <div className="container-fluid">
      <div className="row border-bottom mb-3">
        <div className="col col-md-6">
          <h1>Admin Videos</h1>
        </div>
        <div className="col col-md-6 text-end">
          <Link to="./nuevo" className="btn btn-primary">
            + Agregar
          </Link>
        </div>
      </div>
      <FilterSection />
      <div className="row">{renderVideos()}</div>
    </div>
  );
};

export default AdminVideos;
