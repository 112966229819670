import React from "react";
import CourseRow from "./CourseRow";

const CoursesTable = ({ user, courses }) => {
  const renderCourses = () => {
    if (Array.isArray(courses)) {
      if (courses.length === 0) {
        return (
          <tr>
            <td colSpan={5}>No hay compras registradas.</td>
          </tr>
        );
      }
      return courses.map((course) => (
        <CourseRow user={user} course={course} key={course.course_id} />
      ));
    }
  };

  return (
    <div className="table-responsive">
      <table className="table border">
        <thead className="bg-light border bold small">
          <tr>
            <td>#ID</td>
            <td>Nombre</td>
            <td>Comprados</td>
            <td>Activos</td>
          </tr>
        </thead>
        <tbody>{renderCourses()}</tbody>
      </table>
    </div>
  );
};

export default CoursesTable;
