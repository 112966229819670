import React from "react";

const ProductItem = ({ product }) => {
  const renderLocation = () => {
    if (product.location !== null) {
      if (product.location === "studio") {
        return "TBM Studio";
      }
      return "Zoom";
    }
  };

  const renderBadge = () => {
    return (
      <span
        className={`badge badge-pill text-uppercase text-dark me-2 ${
          product.mode === "on-demand" ? "bg-primary" : "bg-accent"
        }`}
      >
        {product.mode === "live" ? "En Vivo" : "On Demand"}
      </span>
    );
  };
  return (
    <span>
      {product.name} - {renderBadge()} - {renderLocation()}{" "}
    </span>
  );
};

export default ProductItem;
