import {
  SET_PAYMENT_PLAN,
  CREATE_PAYMENT_PLAN,
  PAYMENT_PLANS_RECEIVED,
  SET_PROPERTY_PAYMENT_PLAN,
} from "../types/payment_plans";

const schema = {};

const PaymentPlansReducer = (state, { type, payload }) => {
  switch (type) {
    case PAYMENT_PLANS_RECEIVED:
      return { ...state, payment_plans: payload };
    case SET_PAYMENT_PLAN:
      return { ...state, payment_plan: payload };
    case CREATE_PAYMENT_PLAN:
      return { ...state, payment_plan: schema };
    case SET_PROPERTY_PAYMENT_PLAN: {
      const { key, value } = payload;
      const payment_plan = { ...state.payment_plan };
      payment_plan[key] = value;
      return { ...state, payment_plan };
    }
    default:
      return { ...state };
  }
};

export default PaymentPlansReducer;
