/**
 * Descuentos
 */
export const SET_DESCUENTO = "SET_DESCUENTO";
export const HIDE_SPINNER_DESCUENTO = "HIDE_SPINNER_DESCUENTO";
export const SHOW_SPINNER_DESCUENTO = "SHOW_SPINNER_DESCUENTO";
export const CREATE_DESCUENTO = "CREATE_DESCUENTO";
export const DESCUENTOS_RECIBIDOS = "DESCUENTOS_RECIBIDOS";
export const SET_PROPIEDAD_DESCUENTO = "SET_PROPIEDAD_DESCUENTO";
export const APPEND_PRODUCT_DISCOUNT = "APPEND_PRODUCT_DISCOUNT";
export const REMOVE_PRODUCT_DISCOUNT = "REMOVE_PRODUCT_DISCOUNT";
