import api from "./api";

const route = "/course_accesses";

export default {
  getCourseAccesses: () => api.get(route),
  putCourseAccess: (course_access) => api.put(route, { ...course_access }),
  postCourseAccess: (course_access) => api.post(route, { ...course_access }),
  deleteCourseAccess: (course_access_id) =>
    api.delete(`${route}/${course_access_id}`),
};
