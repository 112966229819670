import api from "./api";

const route = "/lessons";

export default {
  getSingleLesson: (lesson_id) => api.get(`${route}/${lesson_id}`),
  getSectionLessons: (section_id) => api.get(`${route}/section/${section_id}`),
  completeLesson: (lesson_id) => api.post(`${route}/complete`, { lesson_id }),
  postLesson: (lesson) => api.post(route, { ...lesson }),
  putLesson: (lesson) => api.put(route, { ...lesson }),
  deleteLesson: (lesson_id) => api.delete(`${route}/${lesson_id}`),
};
